import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const Home = () => {
  const navigate = useNavigate();

  const investorData = JSON.parse(localStorage.getItem("investorData"));

  useEffect(() => {
    if (investorData?.jwt) {
      navigate("/investments");
      return;
    } else {
      navigate("/login");
    }
  }, []);

  return <div>Redirecting...</div>;
};

export default Home;
