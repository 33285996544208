import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const ExportDataToExcel = ({ investmentData = [], fileName }) => {
  // console.log(investmentData);

  const newInvestMentData = investmentData?.map((ele) => {
    return {
      Domain: ele.SU_Domain,
      "Startup Name": ele.startupname,
      "Investment Type": ele.share_type,
      "Shares Held": ele.shares_held,
      "Price per share (Pre-Money)":ele.iprice,
      "Investment Value (INR)": ele.ival,
      "Pre-Money Valuation": ele.pre_money_valuation_startup, 
      "Current price per share":ele.Cpricepershare,
      "Current Value (INR)": ele.Cval,
      "Latest Valuation of Startup (INR)":ele.Cval_startup,
      "P&L (INR)": ele.pnl,
      "X Returns":(Number(ele.Cpricepershare)/Number(ele.iprice)),
      "Current Stage":ele.stage,
    };
  });

  // console.log(newInvestMentData);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (investmentData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(investmentData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button style={{backgroundColor:"green", border:"none", padding:"3px 10px", color:"white", borderRadius:"5px"}} onClick={(e) => exportToCSV(newInvestMentData, fileName)}>
      Export In Excel <FileDownloadIcon sx={{fontSize:"large", marginLeft:"5px"}}/>
    </Button>
  );
};
