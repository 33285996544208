import React, { useState, useEffect, useRef } from "react";
import { Navigate, json, useNavigate, useParams } from 'react-router';
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { Chart, ChartComponent } from "chart.js/auto";
import { Box, Button, CircularProgress, Divider, Drawer, Grid, Icon, Link, Menu, Paper, Typography } from "@mui/material";
import HailIcon from "@mui/icons-material/Hail";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartPieChartOutlabels from "@energiency/chartjs-plugin-piechart-outlabels";
import logo from "../icons/ialogo.png"; 
import iconfortotalinvestment from "../icons/1.png";
import iconforcurrentvalue from "../icons/2.png"; 
import iconfortotalpnl from "../icons/3.png"; 
import iconforstartups from "../icons/6.png"; 
import iconfordomains from "../icons/8.png"; 
import iconforfvlogo from "../icons/fvlogo.png"; 
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { color } from "chart.js/helpers";
import { left } from "@popperjs/core";
import { Helmet } from 'react-helmet';
import iconforinvestors from "../icons/5.png"; // with import
import favicon from "../icons/fvlogo.ico"; 
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Height, SignalCellularNoSimOutlined } from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";

// const pluginccbc = {
//   id: "customCanvasBackgroundColor",
//   beforeDraw: (chart, args, options) => {
//     const { ctx } = chart;
//     ctx.save();
//     ctx.globalCompositeOperation = "destination-over";
//     ctx.fillStyle = options.color || "transparent";
//     ctx.fillRect(0, 0, chart.width, chart.height);
//     ctx.restore();
//   },
// };

Chart.register(ChartPieChartOutlabels);


function SyndicatePortfolio() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR", 
  });

  // Data table for investor
  const [originalData, setoriginalData] = useState([]); // Original data loaded for the logged on investor
  const [v1inv, setv1inv] = useState([]); // Copy of the original data on which the data is loaded

  // Measures calculated out of the v1inv data
  const [total_inv, settotal_inv] = useState([]);
  const [current_value, setcurrent_value] = useState([]);
  const [pnl, setpnl] = useState([]);
  const [pnl_percent, setpnl_percent] = useState([]);
  const [funds_invested, setfunds_invested] = useState([]);
  const [startups_covered, setstartups_covered] = useState([]);
  const [domains_covered, setdomains_covered] = useState([]);  
  const [investors_covered, setinvestors_covered] = useState([]);
  const [latest_date, setlatest_date] = useState([]);
  const [wealth_manager, setwealth_manager] = useState([]);
  const [rlatest_date, setrlatest_date] = useState([]);
  const [current_date, setcurrent_date] = useState([]);
  const [iname, setiname] = useState([]);
  const [iid, setiid] = useState([]);
  const [tdata, settdata] = useState([]);
  // for horizontal bar chart
  const [chartData, setChartData] = useState(null);
  const [chartHeight, setChartHeight] = useState(null);
  const [isLoading, setIsLoading] = useState(true)

  const param = useParams()

  // for filter selection menu
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  //_____________________________________

  // Logout link function
  const [logoutData, setlogoutData] = useState(null);
  const handleLinkClickfvwm = async () => {
    try {
      localStorage.removeItem("finvolveWealthManagerData")
      Navigate("/wealth-manager-login")
    } catch (error) {
      console.error(error);
    }
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const day = date.getDate().toString().padStart(2,0);
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  
  


  let val = 0;
  // console.log(v1inv)
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      // one set of data for all measures and visuals for investors
      const responsev1inv = await fetch(`${API_BASE_URL}/api/iangels-syndicate-access/portfolio/${param?.email}`)
      const {data} = await responsev1inv.json();

      console.log(data)
      
      const v1inv = data?.map((ele)=>{
        const formatteddIssueDate2 = formatDate(ele.issuedate2)
        const formattedCdate2 = formatDate(ele.Cdate2)
        return {...ele, issuedate2:formatteddIssueDate2, Cdate2:formattedCdate2}
      })
      
      setv1inv(v1inv || []);
      setoriginalData(v1inv || []);
      setIsLoading(false)

      // Calculations for the Measures below

      // Total Investments
      const sumIvalL = v1inv?.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.ival_l);
      }, 0);
      settotal_inv(sumIvalL);
      
      // Current Value Total
      const sumCvalL = v1inv?.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.Cval_l);
      }, 0);
      setcurrent_value(sumCvalL);
      
      // Unrealized P&L Value Total
      const pnl = v1inv?.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.pnl);
      }, 0);
      setpnl(pnl);
      

      // Funds Invested
      const distinctFundsCount = [...new Set(v1inv?.map((investment) => investment.fundname))]?.length;
      setfunds_invested(distinctFundsCount);
      

      // Startups Covered
      const distinctSUCount = [...new Set(v1inv?.map((investment) => investment.startupname))].length;
      setstartups_covered(distinctSUCount);
      
      // Domains Covered
      const distinctdomainCount = [...new Set(v1inv?.map(investment => investment.SU_Domain))].length;
      setdomains_covered(distinctdomainCount);

      // investors Covered
      const distinctinvestorsCount = [...new Set(v1inv?.map((investment) => investment.investor))].length;
      setinvestors_covered(distinctinvestorsCount);
      

      // Use reduce to find the latest date
      const latestDate = v1inv?.reduce((latest, item) => {
        const itemDate = new Date(item.issuedate2);
        return itemDate > latest ? itemDate : latest;
      }, new Date("1900-01-01"));
      // Format the latest date as 'dd-Mmm-yyyy'
      const formattedDate = latestDate?.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      setlatest_date(formattedDate);
      

      // wealth_manager
      const wealthmanagers = [...new Set(v1inv?.map((item) => item.wealthmanager))];
      setwealth_manager(wealthmanagers);
      

      //rlatest_date
      const rlatest_date = v1inv?.reduce((acc, curr) => {
        return acc.Cdate2 > curr.Cdate2 ? acc : curr;
      }).Cdate2;
      setrlatest_date(rlatest_date);
      

      //current_time_am_pm
      const dateObj = new Date();
      const day = dateObj.getDate();
      const month = dateObj.toLocaleString("default", { month: "short" });
      const year = dateObj.getFullYear();
      setcurrent_date(`${day}-${month}-${year}`);

      // Investor Name
      const iname = [...new Set(v1inv?.map((item) => item.investor))];
      setiname(iname);
      

      // Investor ID
      const iid = [...new Set(v1inv?.map((item) => item.wlogin))];
      setiid(iid);
      

      //reverse chronologically arranged dates for the table data
      settdata(
        v1inv?.length>0 ? [...v1inv]?.sort((a, b) => {
          const dateA = new Date(a.issuedate2);
          const dateB = new Date(b.issuedate2);
          return dateB - dateA;
        }) : []
      );
      

      // // data for horizontal bar chart
      // if (investorsdata.length > 0) {
      //   const colors = [
      //     "#3281B7", "#2E927C", "#EF9921", "#7EA8C2", "#73A59C", "#F7CC8B", "#E87934", "#666666",
      //     "#3599B8", "#DFBFBF", "#4AC5BB", "#5F6B6D", "#F4D25A", "#7F898A", "#A4DDEE", "#FDAB89"
      //   ];
      
      //   // Calculate the total Cval_l for individual investors
      //   const investorTotals = investorsdata.map(investorData => ({
      //     investor: investorData.investor,
      //     totalCval: investorData.Cval_l.reduce((total, value) => total + value, 0)
      //   }));
      
      //   // Sort the investorTotals array in descending order based on totalCval
      //   investorTotals.sort((a, b) => b.totalCval - a.totalCval);
      
      //   const investors_and_investment_data = {
      //     labels: [],
      //     datasets: []
      //   };
      
      //   // Create an array of unique fund names
      //   const fundNames = [];
      //   investorsdata.forEach(function(investorData) {
      //     investorData.fundname.forEach(function(fundname) {
      //       if (fundNames.indexOf(fundname) === -1) {
      //         fundNames.push(fundname);
      //       }
      //     });
      //   });
      
      //   // Populate labels array
      //   investorTotals.forEach(function(investorTotal) {
      //     investors_and_investment_data.labels.push(investorTotal.investor);
      //   });
      
      //   // Populate datasets array
      //   fundNames.forEach(function(fundname, index) {
      //     const data = [];
      //     investorTotals.forEach(function(investorTotal) {
      //       const investorData = investorsdata.find(data => data.investor === investorTotal.investor);
      //       const index = investorData.fundname.indexOf(fundname);
      //       const value = (index !== -1) ? investorData.Cval_l[index] : 0;
      //       data.push(value);
      //     });
      
      //     investors_and_investment_data.datasets.push({
      //       label: fundname,
      //       data: data,
      //       backgroundColor: colors[index % colors.length] // Set the background color
      //     });
      //   });
      
      //   setChartData(investors_and_investment_data);
      //   const numBars = investors_and_investment_data.labels.length;
      //   const defaultHeight = 250;
      //   const dynamicHeight = Math.max(numBars * 40, defaultHeight);
      //   setChartHeight(dynamicHeight);
      // }  
      
      // data for horizontal bar chart
if (investorsdata.length > 0) {  

  // Calculate the total Cval_l for individual investors
  const investorTotals = investorsdata.map(investorData => ({
    investor: investorData.investor,
    totalCval: investorData.Cval_l.reduce((total, value) => total + value, 0)
  }));

  // Sort the investorTotals array in descending order based on totalCval
  investorTotals.sort((a, b) => b.totalCval - a.totalCval);

  const investors_and_investment_data = {
    labels: [],
    datasets: []
  };

  // Populate labels array
  investorTotals.forEach(function (investorTotal) {
    investors_and_investment_data.labels.push(investorTotal.investor);
  });

  // Populate datasets array
  investors_and_investment_data.datasets.push({
    label: "Current Value",
    data: investorTotals.map(investorTotal => investorTotal.totalCval),
    backgroundColor: "#3281B7" 
  });

  setChartData(investors_and_investment_data);
  const numBars = investors_and_investment_data.labels.length;
  const defaultHeight = 250;
  const dynamicHeight = Math.max(numBars * 40, defaultHeight);
  setChartHeight(dynamicHeight);
}


    }
    fetchData();
    return () => {
      
    };
  }, [param]);

  useEffect(() => {
    
    // Calculations for the Measures below

    // Total Investments
    const sumIvalL = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.ival_l);
    }, 0);
    settotal_inv(sumIvalL);
    

    // Current Value Total
    const sumCvalL = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.Cval_l);
    }, 0);
    setcurrent_value(sumCvalL);
    

    // Unrealized P&L Value Total
    const pnl = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.pnl);
    }, 0);
    setpnl(pnl);
    

    // Funds Invested
    const distinctFundsCount = [...new Set(v1inv.map((investment) => investment.fundname))].length;
    setfunds_invested(distinctFundsCount);
    

    // Startups Covered
    const distinctSUCount = [...new Set(v1inv.map((investment) => investment.startupname))].length;
    setstartups_covered(distinctSUCount);

    // Domains Covered
    const distinctdomainCount = [...new Set(v1inv.map(investment => investment.SU_Domain))].length;
    setdomains_covered(distinctdomainCount);
    

    // Use reduce to find the latest date
    const latestDate = v1inv.reduce((latest, item) => {
      const itemDate = new Date(item.issuedate2);
      return itemDate > latest ? itemDate : latest;
    }, new Date("1900-01-01"));
    // Format the latest date as 'dd-Mmm-yyyy'
    const formattedDate = latestDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    setlatest_date(formattedDate);
    // console.log("6. Last Investment Date " + (latest_date) + ".");

    // // wealth_manager
    // const wealthmanagers = [...new Set(v1inv.map(item => item.wealthmanager))];
    // setwealth_manager(wealthmanagers);
    // console.log("7. Wealth Manager Name :- "+ (wealth_manager) + ".");

    // //rlatest_date
    // const rlatest_date = v1inv.reduce((acc, curr) => {
    //   return acc.Cdate2 > curr.Cdate2 ? acc : curr;
    // }).Cdate2;
    // setrlatest_date(rlatest_date);
    // console.log("13. Report Updated on :- "+ (rlatest_date) + ".");

    // //current_time_am_pm
    // const dateObj = new Date();
    // const day = dateObj.getDate();
    // const month = dateObj.toLocaleString('default', { month: 'short' });
    // const year = dateObj.getFullYear();
    // setcurrent_date(`${day}-${month}-${year}`);

    // // Investor Name
    // const iname = [...new Set(v1inv.map(item => item.investor))];
    // setiname(iname);
    // console.log("15. Investor Name :- "+ (iname) + ".");

    // // Investor ID
    // const iid = [...new Set(v1inv.map(item => item.ilogin))];
    // setiid(iid);
    // console.log("15. Investor id :- "+ (iid) + ".");

    //reverse chronologically arranged dates for the table data
    settdata(
      [...v1inv].sort((a, b) => {
        const dateA = new Date(a.issuedate2);
        const dateB = new Date(b.issuedate2);
        return dateB - dateA;
      })
    );

    // //data for horizontal bar chart
    // if (investorsdata.length > 0) {
    //   const colors = [
    //     "#3281B7", "#2E927C", "#EF9921", "#7EA8C2", "#73A59C", "#F7CC8B", "#E87934", "#666666",
    //     "#3599B8", "#DFBFBF", "#4AC5BB", "#5F6B6D", "#F4D25A", "#7F898A", "#A4DDEE", "#FDAB89"
    //   ];
    
    //   // Calculate the total Cval_l for individual investors
    //   const investorTotals = investorsdata.map(investorData => ({
    //     investor: investorData.investor,
    //     totalCval: investorData.Cval_l.reduce((total, value) => total + value, 0)
    //   }));
    
    //   // Sort the investorTotals array in descending order based on totalCval
    //   investorTotals.sort((a, b) => b.totalCval - a.totalCval);
    
    //   const investors_and_investment_data = {
    //     labels: [],
    //     datasets: []
    //   };
    
    //   // Create an array of unique fund names
    //   const fundNames = [];
    //   investorsdata.forEach(function(investorData) {
    //     investorData.fundname.forEach(function(fundname) {
    //       if (fundNames.indexOf(fundname) === -1) {
    //         fundNames.push(fundname);
    //       }
    //     });
    //   });
    
    //   // Populate labels array
    //   investorTotals.forEach(function(investorTotal) {
    //     investors_and_investment_data.labels.push(investorTotal.investor);
    //   });
    
    //   // Populate datasets array
    //   fundNames.forEach(function(fundname, index) {
    //     const data = [];
    //     investorTotals.forEach(function(investorTotal) {
    //       const investorData = investorsdata.find(data => data.investor === investorTotal.investor);
    //       const index = investorData.fundname.indexOf(fundname);
    //       const value = (index !== -1) ? investorData.Cval_l[index] : 0;
    //       data.push(value);
    //     });
    
    //     investors_and_investment_data.datasets.push({
    //       label: fundname,
    //       data: data,
    //       backgroundColor: colors[index % colors.length] // Set the background color
    //     });
    //   });
    // console.log(JSON.stringify(investors_and_investment_data,null,2))
    //   setChartData(investors_and_investment_data);
    //   const numBars = investors_and_investment_data.labels.length;
    //   const defaultHeight = 250;
    //   const dynamicHeight = Math.max(numBars * 40, defaultHeight);
    //   setChartHeight(dynamicHeight);
    // }
    // data for horizontal bar chart
if (investorsdata.length > 0) {  

  // Calculate the total Cval_l for individual investors
  const investorTotals = investorsdata.map(investorData => ({
    investor: investorData.investor,
    totalCval: investorData.Cval_l.reduce((total, value) => total + value, 0)
  }));

  // Sort the investorTotals array in descending order based on totalCval
  investorTotals.sort((a, b) => b.totalCval - a.totalCval);

  const investors_and_investment_data = {
    labels: [],
    datasets: []
  };

  // Populate labels array
  investorTotals.forEach(function (investorTotal) {
    investors_and_investment_data.labels.push(investorTotal.investor);
  });

  // Populate datasets array
  investors_and_investment_data.datasets.push({
    label: "Current Value",
    data: investorTotals.map(investorTotal => investorTotal.totalCval),
    backgroundColor: "#3281B7" 
  });

  setChartData(investors_and_investment_data);
  const numBars = investors_and_investment_data.labels.length;
  const defaultHeight = 250;
  const dynamicHeight = Math.max(numBars * 40, defaultHeight);
  setChartHeight(dynamicHeight);
}

    
  }, [v1inv]);



  // below for dropdowns

  const ITEM_HEIGHT = 158;
  const ITEM_PADDING_TOP = 1;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // checkbox for investors
  const checks_investor = [...new Set(originalData.map((item) => item.investor))];
  const [cinvestorName, setcinvestorName] = useState([]);
  const handleChangecinvestor = (event) => {
    const { value } = event.target;
    const selectedinvestor = Array.isArray(value) ? value : [value];
    setcinvestorName(selectedinvestor);
   
    // Filter v1inv array by selected investor
    const filteredV1inv = selectedinvestor.length > 0 ? originalData.filter((item) => selectedinvestor.includes(item.investor)) : originalData;

    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };

  // checkbox for funds
  const checks_funds = [...new Set(originalData.map((item) => item.fundname))];
  const [cfundsName, setcfundsName] = useState([]);
  const handleChangecfunds = (event) => {
    const { value } = event.target;
    const selectedFunds = Array.isArray(value) ? value : [value];
    setcfundsName(selectedFunds);

    // Filter v1inv array by selected funds
    const filteredV1inv = selectedFunds.length > 0 ? originalData.filter((item) => selectedFunds.includes(item.fundname)) : originalData;

    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };

  // checkbox for funds
  const checks_startup = [...new Set(originalData.map((item) => item.startupname))];
  const [cstartupName, setcstartupName] = useState([]);
  const handleChangecstartup = (event) => {
    const { value } = event.target;
    const selectedStartups = Array.isArray(value) ? value : [value];
    setcstartupName(selectedStartups);

    // Filter v1inv array by selected funds
    const filteredV1inv = selectedStartups.length > 0 ? originalData.filter((item) => selectedStartups.includes(item.startupname)) : originalData;

    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };

  // checkbox for Domain
  const checks_domain = [...new Set(originalData.map((item) => item.SU_Domain))];
  const [cdomainName, setcdomainName] = useState([]);
  const handleChangecdomain = (event) => {
    const { value } = event.target;
    const selectedDomains = typeof value === "string" ? value.split(",") : value;
    setcdomainName(selectedDomains);

    // Filter v1inv array by selected funds
    const filteredV1inv = selectedDomains.length > 0 ? originalData.filter((item) => selectedDomains.includes(item.SU_Domain)) : originalData;

    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };

  // above for dropdowns

  // data for table2

  const tdata2 = Object.values(
    v1inv.reduce((acc, { investor, fundname, startupname, ival_l, Cval_l }) => {
      if (!acc[investor]) {
        acc[investor] = {
          investor,
          fundcount: 0,
          startupcount: 0,
          invested_value: 0,
          currentvalue_value: 0,
          pnlvalue: 0,
          pnlpercent: 0,
        };
      }
  
      acc[investor].fundcount += 1;
      acc[investor].startupcount += new Set([startupname]).size;
      acc[investor].invested_value += parseFloat(ival_l);
      acc[investor].currentvalue_value += parseFloat(Cval_l);
      acc[investor].pnlvalue += parseFloat(Cval_l) - parseFloat(ival_l);
      acc[investor].pnlpercent = ((parseFloat(Cval_l) - parseFloat(ival_l)) / parseFloat(ival_l)) * 100;
  
      return acc;
    }, {})
  ).sort((a, b) => b.currentvalue_value - a.currentvalue_value);
  

  

  //other data below

  //for page 2 line chart 2
  const datap2 = {
    labels: [],
    datasets: [
      {
        label: "Number of Investors",
        data: [],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  // Group the input data by month
  const wmpage2line = v1inv.reduce((acc, curr) => {
    const month = curr.issuedate2.split("-")[1]; // Extract the month from the issuedate2 field
    if (!acc[month]) {
      acc[month] = { month: month, count: 0 };
    }
    acc[month].count++;
    return acc;
  }, {});

  // Convert the grouped data into the format expected by Chart.js
  Object.values(wmpage2line).forEach((item) => {
    datap2.labels.push(item.month);
    datap2.datasets[0].data.push(item.count);
  });
//_____________________________________________________________________________________________________________________________________________________________
  // Data and options for Composition of Startups waterfall chart

  // prepping data for wf step1 grouping startups,
  const startupsandcvsum = {};
  for (let i = 0; i < v1inv.length; i++) {
    const startup = v1inv[i];
    const startupName = startup.startupname;
    const Cval_l = parseFloat(startup.Cval_l);

    if (startupsandcvsum[startupName]) {
      startupsandcvsum[startupName].Cval_l += Cval_l;
    } else {
      startupsandcvsum[startupName] = {
        startupname: startupName,
        Cval_l: Cval_l,
      };
    }
  }
  const startupsandcvsumfinal = Object.values(startupsandcvsum);
  
  let startupsData = {};
  startupsandcvsumfinal.sort((a, b) => parseFloat(b.Cval_l) - parseFloat(a.Cval_l));
  let total = 0;
  for (let i = 0; i < startupsandcvsumfinal.length; i++) {
    const startup = startupsandcvsumfinal[i];
    const currentValue = [total, total + parseFloat(startup.Cval_l)];
    startupsData[startup.startupname] = currentValue.join(",");
    total += parseFloat(startup.Cval_l);
  }
  startupsData["Total"] = `0,${total}`;
  // console.log(JSON.stringify(startupsData,null,2));
    // const composition_of_startups_data = {
  //   labels: Object.keys(startupsData),
  //   datasets: [
  //     {
  //       data: Object.values(startupsData).map((value) => {
  //         const numbers = value.split(",");
  //         return [Number(numbers[0]), Number(numbers[1])];
  //       }),        
  //       barPercentage: 1.2,
  //     },
  //   ],
  // };
  // backgroundColor: ["#3281B7", "#2E927C", "#EF9921", "#7EA8C2", "#73A59C", "#F7CC8B", "#E87934", "#666666", "#3599B8", "#DFBFBF", "#4AC5BB", "#5F6B6D", "#F4D25A", "#7F898A", "#A4DDEE", "#FDAB89"],



  const labels = Object.keys(startupsData);
const colors = [];
for (let i = 0; i < labels.length; i++) {
  if (labels[i] !== "Total") {
    colors.push("#2E927C"); // add color for startups
  } else {
    colors.push("#3281B7"); // add color for total
  }
}

const composition_of_startups_data = {
  labels: labels,
  datasets: [
    {
      data: Object.values(startupsData).map((value) => {
        const numbers = value.split(",");
        return [Number(numbers[0]), Number(numbers[1])];
      }),
      backgroundColor: colors,
      barPercentage: 1.2,
    },
  ],
};



  
  // options for the chart
  const composition_of_startups_options  = {  
    animation: false,  
    labels:{
      display:false
    },
    scales: {
              x: {
                title:{
                  display: true,
                  text: 'Startup Name',
                  color: 'black',   
                  font:{
                    Family:"FF DIN",
                    size:'12',     
                    // weight:'bold'
                  }
                },
                grid: {
                  drawOnChartArea: false
                },
                // ticks:{
                //   callback: function(value, index, ticks) {
                //     return '₹'+ value+'L';
                //   }
                // }
              },
              y:{
                beginAtZero:true
                ,
                title:{
                  display: true,
                  text: 'Current Value',
                  color: 'black',   
                  font:{
                    Family:"Segoe UI",
                    size:'12',     
                    // weight:'bold'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  // color: '#F7F7F7'
                },
                ticks:{
                  callback: function(value, index, ticks) {
                    return '₹'+ value+'L';
                  }
                },
                border: {
                  dash: [2,4]
                }
              } 
    },    
    //circumference: 0,
    layout: {
      padding: {
        top:20,
        bottom:15
      },
    }
    ,
    
    plugins: {      
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        bodyColor: "Black",
        titleColor: "Black",
        callbacks: {
          label: function(context) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            var value = context.dataset.data[context.dataIndex];
            value = value.toString(); // convert value to a string
            var parts = value.split(','); // split value into parts using the comma delimiter
            var result = (parseInt(parts[1]) - parseInt(parts[0])).toFixed(2); // subtract the second part from the first part and round the result to two decimal places
            label += "Current Value: " +'₹' + result + 'L';
            return label;
          }
        }
                                    
      },
      legend: {
        display: false,
        position: "right",
        align: "right",
      },
      datalabels: {
        display: 'true',
         align: 'start',
         anchor: 'end',
          //  color:'contrast',
          color:'Black',
          offset: -20,
        // backgroundColor: 'none',        
        font:{
          Family:"Segoe UI",
          size:'14',     
          // weight:'bold'   
        },
        borderRadius:'5',
        formatter: function(value, context) {
          value = value.toString(); // convert value to a string
          var parts = value.split(','); // split value into parts using the comma delimiter
          var result = (parseInt(parts[1]) - parseInt(parts[0])).toFixed(2); // subtract the second part from the first part and round the result to two decimal places
          return '₹' + result + 'L'; // return the result with the rupee symbol and L
        },
        
        
        // formatter: function(value, context) {
        //   return '₹'+ value.toFixed(2) +'L';
        // },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
};

//_____________________________________________________________________________________________________________________________________________________________

  // Domain data - gdatadomain which is used for Domain Composition pie/Doughnut chart
  const domainDataa = {};
  v1inv.forEach((item) => {
    if (!domainDataa[item.SU_Domain]) {
      domainDataa[item.SU_Domain] = 0;
    }
    domainDataa[item.SU_Domain] += parseFloat(item.Cval_l);
  });
  ///sorting the above data in descending order
  const domainData = Object.entries(domainDataa)
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  

  const domain_composition_data = {
    labels: Object.keys(domainData),
    datasets: [
      {
        data: Object.values(domainData).map((value) => value),
        backgroundColor: ["#3281B7", "#2E927C", "#EF9921", "#7EA8C2", "#73A59C", "#F7CC8B", "#E87934", "#666666", "#3599B8", "#DFBFBF", "#4AC5BB", "#5F6B6D", "#F4D25A", "#7F898A", "#A4DDEE", "#FDAB89"],
      },
    ],
  };

  const domain_composition_options = {
    animation: true,  
    // cutout: "30%",
    //circumference: 0,
    radius: "80%",
    layout: {
      padding: 50,
    },
    zoomOutPercentage: "70",
    plugins: {      
      // datalabels: {
      // align: 'center',
      // anchor: 'end',
      // backgroundColor: 'black',
      // borderRadius:'5',
      // display: false,
      // // formatter:(value,context) => {
      // //   return context.chart.data.labels[context.dataIndex] + ': '+ `${value}%`;
      // // }
      // },
      outlabels: {
        display: true,        
        // text: "%l \n₹%v.2L (%p.2) ",
        text: "%l ₹%v.2L",
        color: "black",
        // lineColor: "#4C4A48",
        lineWidth: "1",        
        stretch: 1,
        font: {
          resizable: false,
          family: "Segoe UI",
          size: "10",
        },
        // backgroundColor: false,
        // formatter: function (value, context) {
        //   var percentage = context.dataset.percentages[context.dataIndex];
        //   if (percentage < 5) {
        //     return '';
        //   } else {
        //     return value;
        //   }
        // }
      },
      
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        bodyColor: "Black",
        titleColor: "Black",
        callbacks: {
          label: function(context) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            var value = context.dataset.data[context.dataIndex];
            var total = context.dataset.data.reduce((acc, val) => acc + val, 0);
            var percentage = ((value / total) * 100).toFixed(2);
            var result = value.toFixed(2);
            label += "Current Value: " + '₹' + result + 'L' + " (" + percentage + "% of Total)";
            return label;
          }
        } 
      },
      
      title: {
        display: false,
        align: "start",
        text: "Current Valuation of Holdings - Startups",
        color: "Black",
        fontFamily: "Arial",
        fontSize: "18",
        padding: { bottom: 30 },
      },
      legend: {
        display: false,
        position: "right",
        align: "right",
      },
    },
    //customCanvasBackgroundColor: {
    //  color: 'white',
    //},
    responsive: true,
    maintainAspectRatio: false,
    //  plugins: [
    //    ChartPieChartOutlabels
    //  ]
  };


//_____________________________________________________________________________________________________________________________________________________________
  // Data and Options for the investment value by month bar and line chart
  const transactionsByMonth = {};

  // iterate through the array of objects and calculate the total transactions by month
  v1inv.forEach((item) => {
    const month = item.issuedate2.split("-")[1] + "-" + item.issuedate2.split("-")[2]; // extract month-year in the format "Mon-Year"
    if (transactionsByMonth[month]) {
      transactionsByMonth[month].ival_l = (+transactionsByMonth[month].ival_l + +item.ival_l).toFixed(1); // add to the total and keep only 1 decimal point
      transactionsByMonth[month].Transactions++;
    } else {
      transactionsByMonth[month] = {
        ival_l: item.ival_l,
        issuedate2: month,
        Transactions: 1,
      };
    }
  });

  // convert the object to an array and sort by issuedate2
  const transactionsdata1 = Object.values(transactionsByMonth).sort((a, b) => new Date(a.issuedate2) - new Date(b.issuedate2));

  
  // Create empty arrays to hold the labels and data
  const td1labels = [];
  const td1dataset1 = [];
  const td1dataset2 = [];

  // Iterate over the data array and extract the necessary values
  transactionsdata1.forEach((item) => {
    td1labels.push(item.issuedate2);
    td1dataset1.push(item.ival_l);
    td1dataset2.push(item.Transactions);
  });

  // Define the datasets object with two datasets (one for each line/bar)
  const td1datasets = [
    {
      label: "Cval_l",
      data: td1dataset1,
      backgroundColor: "#3281B7",
      type: "bar",
      // borderColor: "blue",
      fill: false,
    },
    // {
    //   label: "Transactions",
    //   data: td1dataset2,
    //   type: "line",
    //   backgroundColor: "#2E927C",
    // },
  ];


  // Define the chart data object with labels and datasets
  const investment_value_by_month_data = {
    labels: td1labels,
    datasets: td1datasets,
  };

// options for the investment value by month bar and line chart
const investment_value_by_month_options  = {    
  labels:{
    display:false
  },
  scales: {
            y: {
              title:{
                display: true,
                text: 'Investment Value',
                color: 'black',   
                font:{
                  Family:"FF DIN",
                  size:'12',     
                  // weight:'bold'
                }
              },
              grid: {
                drawOnChartArea: true,
                // color: '#F7F7F7'
              },
              border: {
                dash: [2,4]
              },
              ticks:{
                callback: function(value, index, ticks) {
                  return '₹'+ value+'L';
                }
              }
            },
            x:{              
              title:{
                display: true,
                text: 'Month - Year',
                color: 'black',   
                font:{
                  Family:"Segoe UI",
                  size:'12',     
                  // weight:'bold'
                }
              },
              grid:{
                drawOnChartArea:false
              }              
            } 
  },    
  //circumference: 0,
  layout: {
    padding: {
      top:20,
      bottom:15
    },
  },
  
  plugins: {      
    tooltip: {
      enabled: true,
      backgroundColor: "white",
      bodyColor: "Black",
      titleColor: "Black",
      callbacks: {
        label: function(context) {
          var label = context.dataset.label || '';
          if (label) {
            label = "Investment Value : ";
          }
          var value = context.dataset.data[context.dataIndex];            
          var result = Number(value).toFixed(2); 
          label += '₹' + result + 'L';
          return label;
        }
      }     
    },    
    legend: {
      display: false,
      position: "right",
      align: "right",
    },
    datalabels: {
      display: 'true',
        align: 'start',
        anchor: 'end',                  
      //  color:'contrast',
       color:'Black',
       offset: -20,
      // backgroundColor: 'none',        
      font:{
        Family:"Segoe UI",
        size:'14',     
        weight:'none'   
      },
      borderRadius:'5',
       formatter: function(value, context) {
         return '₹'+ Number(value).toFixed(2) +'L';
       },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};


  //------------------------------------------------
 
  // Data and options for Investors Engaged Line chart
  const td2dataset = [  
    {
      label: "Investors Engaged",
      data: td1dataset2,
      borderColor: '#2E927C',
      backgroundColor: '#EF9921',  
      pointBackgroundColor: '#EF9921',  
      pointBorderColor: '#EF9921',  
      pointStyle: 'rect',
      rotation: 45,
      radius: 4,
      type: "line",
      
    },
  ];

    const investors_engaged_data = {
      labels: td1labels,
      datasets: td2dataset         
    };  

  const investors_engaged_options  = {    
    labels:{
      display:false
    },
    scales: {
              y: {
                title:{
                  display: true,
                  text: 'Investor Count',
                  color: 'black',   
                  font:{
                    Family:"FF DIN",
                    size:'12',     
                    // weight:'bold'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  // color: '#F7F7F7'
                },
                border: {
                  dash: [2,4]
                },
                // ticks:{
                //   callback: function(value, index, ticks) {
                //     return '₹'+ value+'L';
                //   }
                // }
              },
              x:{              
                title:{
                  display: true,
                  text: 'Month - Year',
                  color: 'black',   
                  font:{
                    Family:"Segoe UI",
                    size:'12',     
                    // weight:'bold'
                  }
                },
                grid:{
                  drawOnChartArea:false
                }              
              } 
    },    
    //circumference: 0,
    layout: {
      padding: {
        top:20,
        bottom:15
      },
    },
    
    plugins: {      
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        bodyColor: "Black",
        titleColor: "Black"                   
      },
      legend: {
        display: false,
        position: "right",
        align: "right",
      },
      datalabels: {
        display: 'true',
         align: 'start',
         anchor: 'end',                  
        //  color:'contrast',
          color:'Black',
          offset: -20,
        // backgroundColor: 'none',        
        font:{
          Family:"Segoe UI",
          size:'14',     
          weight:'none'   
        },
        borderRadius:'5',
        //  formatter: function(value, context) {
        //    return '₹'+ Number(value).toFixed(2) +'L';
        //  },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  //____________________________________________________________________________________________________

  // Funds Data -  gData used for AUM breakdown

  const fundsData = {};
  v1inv.forEach((item) => {
    if (!fundsData[item.fundname]) {
      fundsData[item.fundname] = 0;
    }
    fundsData[item.fundname] += parseFloat(item.Cval_l);
  });

  const aum_breakdown_data = {
    labels: Object.keys(fundsData),
    datasets: [
      {
        data: Object.values(fundsData).map((value) => value),
        backgroundColor: "#3281B7",
        barPercentage: 0.7
      },
    ],
  };

//   const aum_breakdown_options  = {    
//     labels:{
//       display:false
//     },
//     scales: {
//               y: {
//                 title:{
//                   display: true,
//                   text: 'Current Value',
//                   color: 'black',   
//                   font:{
//                     Family:"FF DIN",
//                     size:'12',     
//                     // weight:'bold'
//                   }
//                 },
//                 grid: {
//                   drawOnChartArea: true,
//                   // color: '#F7F7F7'
//                 },
//                 border: {
//                   dash: [2,4]
//                 },
//                 ticks:{
//                   callback: function(value, index, ticks) {
//                     return '₹'+ value+'L';
//                   }
//                 }
//               },
//               x:{
//                 ticks: {
//                         autoSkip: false,
//                         maxRotation: 0,
//                         callback: (value, index, values) => {
//                             let label = aum_breakdown_data.labels[value];
//                             if(label == null){
//                                 return '';
//                             }
//                             if (label.length > 15) {
//                                 label = label.substring(0, 15);
//                                 label += '..';
//                             }
//                                 return label;
//                         },
//                   },   
//                 title:{
//                   display: true,
//                   text: 'Startup Domain',
//                   color: 'black',   
//                   font:{
//                     Family:"Segoe UI",
//                     size:'12',     
//                     // weight:'bold'
//                   }
//                 },
//                 grid:{
//                   drawOnChartArea:false
//                 }              
//               } 
//     },    
//     //circumference: 0,
//     layout: {
//       padding: {
//         top:20,
//         bottom:15
//       },
//     },
    
//     plugins: {      
//       tooltip: {
//         enabled: true,
//         backgroundColor: "white",
//         bodyColor: "Black",
//         titleColor: "Black",
//         callbacks: {
//           label: function(context) {
//             var label = context.dataset.label || '';
//             if (label) {
//               label += ': ';
//             }
//             var value = context.dataset.data[context.dataIndex];            
//             var result = value.toFixed(2); // subtract the second part from the first part and round the result to two decimal places
//             label += "AUM: " +'₹' + result + 'L';
//             return label;
//           }
//         }                  
//       },
//       legend: {
//         display: false,
//         position: "right",
//         align: "right",
//       },
//       datalabels: {
//         display: 'true',
//          align: 'start',
//          anchor: 'end',                  
//         //  color:'contrast',
//          color:'Black',
//          offset: -20,
//         // backgroundColor: 'none',        
//         font:{
//           Family:"Segoe UI",
//           size:'14',     
//           weight:'none'   
//         },
//         borderRadius:'5',
//         formatter: function(value, context) {
//           return '₹'+ value.toFixed(2) +'L';
//         },
//       },
//     },
//     responsive: true,
//     maintainAspectRatio: false,
// };

//___________________________________________________________________________________________________
// data and options for investors and ivnestments - fund wise chart
  

const investorsdata = v1inv.reduce((acc, curr) => {
  const existingInvestor = acc.find(item => item.investor === curr.investor);
  if (existingInvestor) {
    const existingFundIndex = existingInvestor.fundname.findIndex(item => item === curr.fundname);
    if (existingFundIndex > -1) {
      existingInvestor.Cval_l[existingFundIndex] += parseFloat(curr.Cval_l);
    } else {
      existingInvestor.fundname.push(curr.fundname);
      existingInvestor.Cval_l.push(parseFloat(curr.Cval_l));
    }
  } else {
    acc.push({
      investor: curr.investor,
      fundname: [curr.fundname],
      Cval_l: [parseFloat(curr.Cval_l)]
    });
  }
  return acc;
}, []);


//_____ 

// // create an empty object to hold the final output
// const investors_and_investment_data = {
//   labels: [1,2],
//   datasets: [1,2]
// };

// Create an array of unique fund names
// const fundNames = investorsdata.reduce((acc, curr) => {
//   return [...acc, ...curr.fundname.filter((name) => !acc.includes(name))];
// }, []);



// // Create an object where each key is an investor name, and the value is an array of Cval_l values for each fund they invested in
// const investorData = investorsdata.reduce((acc, curr) => {
//   const investor = curr.investor;
//   const cVals = curr.Cval_l;
//   const data = {};
//   curr.fundname.forEach((fund, index) => {
//     data[fund] = cVals[index];
//   });

//   if (acc[investor]) {
//     acc[investor].push(data);
//   } else {
//     acc[investor] = [data];
//   }

//   return acc;
// }, {});




// // Create the data object for the chart
// const investors_and_investment_data2 = {
//   labels: Object.keys(investorData),
//   datasets: fundNames.map((name, index) => {
//     return {
//       label: name,
//       backgroundColor: 'black', // Generate a random hex color
//       data: Object.values(investorData).map((data) => {
//         return data[name] || 0; // If the investor did not invest in this fund, set the value to 0
//       })
//     };
//   })
// };


// const chartLabels = investorsdata.map(data => data.investor);


// // // Generate chart datasets
// const chartDatasets = [];

// const fundNames = [...new Set(investorsdata.flatMap(data => data.fundname))];

// for (const fundName of fundNames) {
//   const fundData = investorsdata.map(data => {
//     const index = data.fundname.indexOf(fundName);
//     return (index >= 0) ? data.Cval_l[index] : 0;
//   });
//   chartDatasets.push({
//     label: fundName,
//     data: fundData,
//     // backgroundColor: 'a0a7f9'
//     //  stack: 'investors',
//   });
// }




// // Generate chart data
// const investors_and_investment_data3= {
//   labels: chartLabels,
//   datasets: chartDatasets,  
// };



 


 

 var investors_and_investment_data = {
  labels: [],
  datasets: []
};

// // Create an array of unique fund names
// var fundNames = [];
// investorsdata.forEach(function(investorData) {
//   investorData.fundname.forEach(function(fundname) {
//     if (fundNames.indexOf(fundname) === -1) {
//       fundNames.push(fundname);
//     }
//   });
// });

// // Populate labels array
// investorsdata.forEach(function(investorData) {
//   investors_and_investment_data3.labels.push(investorData.investor);
// });

// // Populate datasets array
// fundNames.forEach(function(fundname) {
//   var data = [];
//   investorsdata.forEach(function(investorData) {
//     var index = investorData.fundname.indexOf(fundname);
//     var value = (index !== -1) ? investorData.Cval_l[index] : 0;
//     data.push(value);
//   });
//   investors_and_investment_data3.datasets.push({
//     label: fundname,
//     data: data
//   });
// });

  //___________

  const investors_and_investment_options = {
    indexAxis: "y",
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Current Value",
          // color: "black",
          font: {
            Family: "FF DIN",
            size: "12",
            // weight:'bold'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return "₹" + value + "L";
          },
        },
      },
      y: {
        stacked: true,
        barPercentage: 1,
        categoryPercentage: 1,
        title: {
          display: false,
          text: "Investors",
          // color: "black",
          font: {
            Family: "Segoe UI",
            size: "12",
            // weight:'bold'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    //circumference: 0,
    layout: {
      padding: {
        top: 20,
        bottom: 15,
      },
    },

    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        bodyColor: "Black",
        titleColor: "Black",
        callbacks: {
          label: function(context) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            var value = context.dataset.data[context.dataIndex];            
            var result = value.toFixed(2); // subtract the second part from the first part and round the result to two decimal places
            label += '₹' + result + 'L';
            return label;
          }
        } 
      },
      legend: {
        display: false,
        position: "top",
        align: "center",
      },
      datalabels: {
        display: true,
        align: "center",
        anchor: "center",
        color: "contrast",
        font: {
          family: "Segoe UI",
          size: 12,
          weight: "none"
        },
        borderRadius: 5,
        formatter: function(value, context) {
          if (value === 0 || value <= 2) {
            return '';
          } else {
            return '₹' + value.toFixed(2) + 'L';
          }
        }
      }
        
    },
    responsive: false,
    maintainAspectRatio: false,
  };
 
    // const numBars = investors_and_investment_data.labels.length;
    // const height = numBars * 40; // adjust this value as needed
    
  
  
  //________________________________________________________________________________________________________________

  
  function getColor(ctx) {
    const colors = ["#3281B7", "#2E927C", "#EF9921", "#7EA8C2", "#73A59C", "#F7CC8B", "#E87934", "#666666", "#3599B8", "#DFBFBF", "#4AC5BB", "#5F6B6D", "#F4D25A", "#7F898A", "#A4DDEE", "#FDAB89"];
    
    if (ctx.type === "data") {
      return colors[ctx.raw._data._idx];
    } else return "Black";
  }

  

  // const bgColors = ["#3281B7", "#2E927C", "#EF9921", "#7EA8C2", "#73A59C", "#F7CC8B", "#3281B7", "#2E927C", "#EF9921", "#7EA8C2", "#73A59C", "#F7CC8B"];
  // const dataaa = {
  //   labels: data.map((startup) => startup.Startup_Name),
  //   datasets: [
  //     {
  //       data: data.map((startup) => startup.t_amt_inv),
  //       //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
  //       //backgroundColor:  bgColors.map((color) => color.at),
  //       backgroundColor: ["#3281B7"],
  //       //(ctx)=> this.getColor(ctx)
  //     },
  //   ],
  // };

  //console.log(typeof v1m1);
  //  console.log(JSON.stringify(tmdataaa1, null, 2));
  //console.log(JSON.stringify(data2, null, 2));

  // const chartContainerRef = useRef(null);
  // useEffect(() => {
  //   const numBars = investors_and_investment_data.labels.length;
  //   const defaultHeight = 250;
  //   const dynamicHeight = Math.max(numBars * 40, defaultHeight);
  //   setChartHeight(dynamicHeight);   
  
  //   const handleResize = () => {
  //     if (chartContainerRef.current) {
  //       const newHeight = chartContainerRef.current.offsetHeight;
  //       setChartHeight(newHeight);
  //     }
  //   };
  
  //   window.addEventListener('resize', handleResize);
  //   handleResize(); // Set initial height
  
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  

  // // re-render for pie
  // const [chartData2, setChartData2] = useState(domain_composition_data);
  // const hbchartRef = useRef(null);
  
  // useEffect(() => {
  //   const resizeHandler = () => {
  //     const chartInstance = hbchartRef.current.chartInstance;
  //     if (chartInstance) {
  //       // Update the chart data as needed
  //       const newChartData = { ...domain_composition_data };
  //       setChartData2(newChartData);
  //       chartInstance.update();
  //     }
  //   };
  
  //   window.addEventListener("resize", resizeHandler);
  
  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", resizeHandler);
  //   };
  // }, [domain_composition_data, v1inv]);

  if(isLoading){
    return <div style={{width:"full", margin:"auto", position:"fixed", left:"50%", top:"100px", height:"200px",  display:"flex", justifyItems:"center", alignItems:"center"}}><CircularProgress/></div>
  }

  return (
    <div>      
      <Helmet>
                <title>Finvolve - Wealth Manager Dashboard</title>
                <link rel="icon" type="image/png" href={favicon} 
                sizes="16x16" 
                />
        </Helmet>
      {/* First item on the top, second item on the top in mobile view  */}
      
      <Grid container sx={{ backgroundColor: "#F7F7F7", border: { xs: "none", lg: "none" } }}>
      
        <Grid item xs={12} sm={6} lg={6} order={{ xs: 2, sm: 2, md: 2, lg: 1 }} sx={{border:'none'}}
        container
        display={'flex'}
        justifyContent={'flex-start'}>          
          
        <Button startIcon={<MenuIcon />} size="large" onClick={handleClickOpen}></Button>
        
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose} >
        <DialogTitle sx={{backgroundColor: "#F7F7F7" }}>Filter Selection Menu</DialogTitle>
        <DialogContent sx={{ width: 320, height: 230, backgroundColor: "#F7F7F7"  }}>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', backgroundColor: "#F7F7F7" }}>
          <Grid
          container
          display={"flex"}
          // direction={"row-reverse"}
          sx={{ backgroundColor: "#F7F7F7" }}
          // sx={{ border: "solid"}}
         >       
        <Grid item lg={12}>
          <FormControl sx={{ m: 1, width: 300 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">Investors</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={cinvestorName}
              onChange={handleChangecinvestor}
              input={<OutlinedInput label="Investors" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}>
              {checks_investor.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={cinvestorName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={6}>
          <FormControl sx={{ m: 1, width: 300 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">Funds</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={cfundsName}
              onChange={handleChangecfunds}
              input={<OutlinedInput label="Funds" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}>
              {checks_funds.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={cfundsName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, width: 300 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">Startup</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={cstartupName}
              onChange={handleChangecstartup}
              input={<OutlinedInput label="Funds" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}>
              {checks_startup.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={cstartupName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, width: 300 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">Domain</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={cdomainName}
              onChange={handleChangecdomain}
              input={<OutlinedInput label="Funds" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}>
              {checks_domain.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={cdomainName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{backgroundColor: "#F7F7F7"}} >          
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>      
          <Typography 
          pl={{ xs: 0, lg: 0 }} 
          pt={{ xs: 0, lg: 1 }} 
          pb={{ xs: 0, lg: 1 }} 
          m={0} 
          fontFamily="Segoe UI" variant={"h6"}>
            Hi, {wealth_manager}
          </Typography>
      
        </Grid>
      
        <Grid item pt={1} xs={12} lg={6} sx={{ border: "none" }} order={{ xs: 1, sm: 1, md: 1, lg: 2 }} pr={{ xs: 0, lg: 7 }}>
          
          <Grid display={"flex"} direction={{ xs: "row", lg: "row-reverse" }} justifyContent={{ xs: "flex-start", lg: "end" }} pr={1} mt={0} container sx={{ backgroundColor: "#F7F7F7" }} border={"none"}>          
            {/* <Grid item pl={{ xs: 2, lg: 2 }} pt={{ xs: 0, lg: 1 }} sx={{ border: "none" }} xs={6} lg={2} pr={3} mr={4}>
          
              <img
                src={iconforfvlogo}
                alt="Finvolve Logo"
                style={{
                  maxWidth: "180px",
                  maxHeight: "180px",
                  paddingRight: "10px",
                }}
              />
            </Grid> */}
            
            <Grid item pt={{lg:3}} pl={{ xs: 0, lg: 0 }} xs={6} lg={5} sx={{ border: "none" }} align={{ lg: "right" }}>
              {/* <Typography fontFamily="Segoe UI" fontSize={{ xs: 12, lg: 16 }} align="right">
                WM ID :- {iid}, ({current_date})
              </Typography> */}
              {/* <Typography fontFamily="Segoe UI" fontSize={{ xs: 12, lg: 16 }} fontWeight={"bold"} align="right">
                <Link href='/wmpassword-reset'  underline="always">Reset Password</Link> &nbsp; &nbsp;
                <Link href='/wealth-manager-login' onClick={handleLinkClickfvwm} underline="always">Sign Out</Link>
              </Typography>               */}
            </Grid>            
          </Grid>

        </Grid>

      </Grid>

      <Grid container>
        <Grid item lg={12}>
          <Divider sx={{ color: "#DBDBDB" }}></Divider>
        </Grid>
      </Grid>      
      <Grid
        container
        sx={{ backgroundColor: "#F7F7F7" }}
        display={"flex"}
        justifyContent="space-evenly"
        pl={{ xs: 0, lg: 2 }}
        pt={{ xs: 1, lg: 1 }}
        //sx={{ border: "solid"}}
      >
        <Grid item pt={{ xs: 1 }} pr={{ xs: 0, lg: 2 }} xs={5} lg={2}>
          <Paper
          // maxHeight={{xs:100}}
          >
            <img src={iconfortotalinvestment} alt="Total Investment" style={{ float: "left", margin: 10, border: "none" }} />
            {/* <img src={logo}  style={{ width: 300, height: 35 }}/> */}
            {/* <Typography fontFamily="Segoe UI" fontSize={30} color='#000000' align='right' pr={2}> {formatter.format(t_amt_inv)} Cr </Typography> */}
            <Typography fontFamily="Segoe UI" fontSize={{ xs: 22, lg: 30 }} color="#000000" align="right" pr={2}>
              {" "}
              {formatter.format(total_inv)}L{" "}
            </Typography>
            <Typography fontFamily="Segoe UI" fontSize={14} color="#80898A" align="right" pr={2} pb={{ xs: 1, lg: 1 }}>
              {" "}
              Total Investments{" "}
            </Typography>
          </Paper>
        </Grid>
        <Grid item pt={{ xs: 1 }} pr={{ xs: 0, lg: 2 }} xs={5} lg={2}>
          <Paper>
            <img src={iconforcurrentvalue} alt="Current Value" style={{ float: "left", margin: 10 }} />
            {/* <Typography fontFamily="Segoe UI" fontSize={30} color='#000000' align='right' pr={2}> {formatter.format(t_curr_val)} Cr </Typography> */}
            <Typography fontFamily="Segoe UI" fontSize={{ xs: 22, lg: 30 }} color="#000000" align="right" pr={2}>
              {" "}
              {formatter.format(current_value)}L{" "}
            </Typography>
            <Typography fontFamily="Segoe UI" fontSize={14} color="#80898A" align="right" pr={2} pb={{ xs: 1, lg: 1 }}>
              {" "}
              Current AUM{" "}
            </Typography>
          </Paper>
        </Grid>
        <Grid item pt={{ xs: 1 }} pr={{ xs: 0, lg: 2 }} xs={5} lg={2}>
          <Paper>
            <img src={iconfortotalpnl} alt="Total P&L" style={{ float: "left", margin: 10 }} />
            {/* <Typography fontFamily="Segoe UI" fontSize={30} color='#000000' align='right' pr={2} sx={{ color: t_pnl >= 0 ? 'green' : 'red' }}> {formatter.format(t_pnl)} Cr </Typography> */}
            <Typography fontFamily="Segoe UI" fontSize={{ xs: 22, lg: 30 }} color="#000000" align="right" pr={2} sx={{ color: pnl >= 0 ? "green" : "red" }}>
              {" "}
              {formatter.format(pnl)}{" "}
            </Typography>
            {/* <Typography fontFamily="Segoe UI" fontSize={14} color='#000000' align='right' pr={2} sx={{ color: t_pnl >= 0 ? 'green' : 'red' }}>+35.00%</Typography> */}
            <Typography fontFamily="Segoe UI" fontSize={14} color="#80898A" align="right" pr={2} border={"none"} pb={{ xs: 1, lg: 1 }}>
              {" "}
              Unrealized P&L{" "}
            </Typography>
          </Paper>
        </Grid>        
        <Grid item pt={{ xs: 1 }} pr={{ xs: 0, lg: 2 }} xs={5} lg={2}>
          <Paper>
            <img src={iconforinvestors} alt="Latest date of Investment" style={{ float: "left", margin: 10 }} />
            {/* <Typography fontFamily="Segoe UI" fontSize={30} color='#000000' align='right' pr={2}> {formatter.format(t_amt_inv)} Cr </Typography> */}
            <Typography fontFamily="Segoe UI" fontSize={{ xs: 22, lg: 28 }} color="#000000" align="right" pr={2}>
              {investors_covered}
            </Typography>
            <Typography fontFamily="Segoe UI" fontSize={14} color="#80898A" align="right" pr={2} pb={{ xs: 1, lg: 1 }}>
              {" "}
              Investors{" "}
            </Typography>
          </Paper>
        </Grid>
        <Grid item pt={{ xs: 1 }} pr={{ xs: 0, lg: 2 }} xs={5} lg={2}>
          <Paper>
            <img src={iconforstartups} alt="Startups" style={{ float: "left", margin: 10 }} />
            {/* <Typography fontFamily="Segoe UI" fontSize={30} color='#000000' align='right' pr={2}> {formatter.format(t_amt_inv)} Cr </Typography> */}
            <Typography fontFamily="Segoe UI" fontSize={{ xs: 22, lg: 30 }} color="#000000" align="right" pr={2}>
              {" "}
              {startups_covered}{" "}
            </Typography>
            <Typography fontFamily="Segoe UI" fontSize={14} color="#80898A" align="right" pr={2} pb={{ xs: 1, lg: 1 }}>
              {" "}
              Startups Covered{" "}
            </Typography>
          </Paper>
        </Grid>
        <Grid item pt={{ xs: 1 }} pr={{ xs: 0, lg: 2 }} xs={5} lg={2}>
          <Paper>
            <img src={iconfordomains} alt="Domains covered" style={{ float: "left", margin: 10 }} />
            {/* <Typography fontFamily="Segoe UI" fontSize={30} color='#000000' align='right' pr={2} sx={{ color: (t_curr_val / t_amt_inv) >= 1 ? 'green' : 'red' }}> {formatter.format(t_curr_val / t_amt_inv)}x </Typography> */}
            <Typography fontFamily="Segoe UI" fontSize={{ xs: 22, lg: 30 }} color="#000000" align="right" pr={2}>
              {" "}
              {domains_covered}{" "}
            </Typography>
            <Typography fontFamily="Segoe UI" fontSize={14} color="#80898A" align="right" pb={{ lg: 1 }} pr={2} display={{ xs: "none", lg: "block" }}>
              {" "}
              Domains Covered{" "}
            </Typography>
            <Typography fontFamily="Segoe UI" fontSize={14} color="#80898A" align="right" pr={2} pb={{ xs: 1, lg: 1 }} display={{ lg: "none" }}>
              {" "}
              Domains Covered{" "}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ backgroundColor: "#F7F7F7" }}
        display={"flex"}
        justifyContent="start"
        pt={0}
        mb={0}
        //sx={{ border: "solid" }}     
      >
        <Grid item p={{ xs: 3, lg: 0 }} pr={{ lg: 0 }} pt={{ xs: 2, lg: 1 }} pl={{ xs: 3, lg: 2 }} xs={12} lg={6}
        //  sx={{ overflowY: "scroll", maxHeight: "100%" }}
        >
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none"                            
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"} pb={1}>
              Current value of Investments - Investor Wise
            </Typography>   
            <Grid container display="flex" sx={{ border: 'none', overflowY: 'auto', height:'290px'}} 
            // ref={chartContainerRef}
            >
            {chartData && (
              <Bar
                key={chartHeight}
                p={1}
                height={chartHeight}
                width="800"
                data={chartData}
                options={investors_and_investment_options}
                plugins={[ChartDataLabels]}
                />
              )}
            </Grid>                            
          </Paper>          
        </Grid>

        <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 2 }} pr={{ xs: 3, lg: 2 }} xs={12} lg={6}>
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none",
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"}>
              Composition of Startups
            </Typography>
            <Bar data={composition_of_startups_data} plugins={[ChartDataLabels]} options={composition_of_startups_options} />
            {/* <canvas ref={piechartRef} />               */}
          </Paper>
        </Grid>
      </Grid>

      {/* <Grid container
        sx={{ backgroundColor: '#F7F7F7' }}
        display={'flex'}
        justifyContent='space-evenly'
        pt={1}
        //sx={{ border: "solid" }}
      >
        <Grid item p={1} m={1} lg={12}>
          <Paper elevation={3} sx={{ paddingLeft:'10px', paddingRight:'10px', borderRadius: '10px', border: 'none', height: '120px'}} >
          <TreemapChart data={dataaa} />
          </Paper></Grid>
      </Grid> */}

      <Grid
        container
        sx={{ backgroundColor: "#F7F7F7" }}
        display={"flex"}
        justifyContent="start"
        pt={0}
        mb={0}
        //sx={{ border: "solid" }}
      >
        <Grid item p={{ xs: 3, lg: 0 }} pr={{ lg: 0 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 2 }} xs={12} lg={7}>
          <Paper
            sx={{
              padding: "10px",
              maxWidth: "100%",              
              height: "320px",
              borderRadius: "10px",
            }}
            elevation={3}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"}>
              {" "}
              Investor Summary{" "}
            </Typography>
            <TableContainer m={1} p={1} sx={{ maxHeight: "90%", maxWidth: "100%" }}>
              <Table aria-label="simple table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "80px",
                        color: "#A6A6A6",
                      }}
                      align="left">
                      Investor
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Funds Invested
                    </TableCell> */}
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Startups Covered
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Amount Invested
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Current Value
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      P&L
                    </TableCell>
                    <TableCell 
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      P&L%
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tdata2.map((tdata2, index) => (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          width: "80px",
                          overflow: "hidden",
                          whiteSpace: "wrap",
                        }}
                        align="left">
                        {tdata2.investor}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {tdata2.fundcount}
                      </TableCell> */}
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {tdata2.startupcount}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {/* ₹{v1inv.ival_l.toFixed(2)}L */}₹{parseFloat(tdata2.invested_value).toFixed(2)}L
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        ₹{parseFloat(tdata2.currentvalue_value).toFixed(2)}L
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        ₹{parseFloat(tdata2.pnlvalue).toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {parseFloat(tdata2.pnlpercent).toFixed(2)}%
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 2 }} pr={{ xs: 3, lg: 2 }}  xs={12} lg={5}>
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none",
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"} pb={1}>
              Domain Composition
            </Typography>
            <Doughnut data={domain_composition_data} 
             plugins={[ChartPieChartOutlabels]} 
            //  plugins={[ChartDataLabels]} 
             options={domain_composition_options} 
            />
          </Paper>
        </Grid>
        {/* <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 0 }} pr={{ xs: 3, lg: 2 }} xs={12} lg={3}>
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none",
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"}>
              AUM Breakdown
            </Typography>
            <Bar data={aum_breakdown_data} plugins={[ChartDataLabels]} options={aum_breakdown_options} />
          </Paper>
        </Grid> */}
      </Grid>
      <Grid
        pt={0}
        container
        display={"flex"}
        wrap="nowrap"
        sx={{ backgroundColor: "#F7F7F7" }}
        justifyContent="space-evenly"
        //sx={{ border: "solid" }}
      >
        {/* <Grid item p={2} m={0} xs={12} lg={12}> */}
        <Grid item p={{ xs: 3, lg: 0 }} pr={{ lg: 2 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 2 }} xs={12} lg={12}>
          <Paper
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "300px",
              borderRadius: "10px",
            }}
            elevation={3}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"}>
              {" "}
              Transaction Summary{" "}
            </Typography>
            <TableContainer m={1} p={1} sx={{ maxHeight: "90%", maxWidth: "100%" }}>
              <Table aria-label="simple table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="left">
                      {" "}
                      Issue Date{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="left">
                      Investor
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Fund
                    </TableCell> */}
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Invested Startup
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Round
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "90px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Units
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Avg. NAV
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Current NAV
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Amount Invested
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      Current Value
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      P&L
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "18",
                        width: "40px",
                        color: "#A6A6A6",
                      }}
                      align="center">
                      P&L%
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tdata.map((tdata, index) => (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        component="th"
                        scope="row">
                        {tdata.issuedate2.split(' ')[0]}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="left">
                        {tdata.investor}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {tdata.fundname}
                      </TableCell> */}
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {tdata.startupname}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {tdata.round}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {tdata.units}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        ₹{tdata.unitprice}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        ₹{tdata.Cunitprice}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {/* ₹{v1inv.ival_l.toFixed(2)}L */}₹{parseFloat(tdata.ival_l).toFixed(2)}L
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        ₹{parseFloat(tdata.Cval_l).toFixed(2)}L
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        ₹{parseFloat(tdata.pnl).toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Segoe UI",
                          fontSize: "20",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        align="center">
                        {parseFloat(tdata.pnlp).toFixed(2)}%
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ backgroundColor: "#F7F7F7" }}
        display={"flex"}
        justifyContent="start"
        pt={0}
        mb={0}
        //sx={{ border: "solid" }}
      >
        <Grid item p={{ xs: 3, lg: 0 }} pr={{ lg: 0 }} pt={{ xs: 2, lg: 1 }} pl={{ xs: 3, lg: 2 }} xs={12} lg={6}>
          {/* <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 2, lg: 1 }} pl={{ xs: 3, lg: 2 }} xs={12} lg={6}> */}
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none",
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"}>
              Investment Value by Month
            </Typography>
            <Bar data={investment_value_by_month_data} plugins={[ChartDataLabels]} options={investment_value_by_month_options}/>
          </Paper>
        </Grid>
        <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 2 }} pr={{ xs: 3, lg: 2 }} xs={12} lg={6}>
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none",
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"}>
              Investors Engaged
            </Typography>
            <Bar data={investors_engaged_data} plugins={[ChartDataLabels]} options={investors_engaged_options} 
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ backgroundColor: "#F7F7F7" }}
        display={"flex"}
        justifyContent="flex-start"
        pt={0}
        mt={0}
        //sx={{ border: "solid" }}
      >
        <Grid item lg={6}>
          <Grid
            container
            display={"flex"}
            justifyContent="flex-start"
            pt={0}
            mt={0}
            //sx={{ border: "solid" }}
          ></Grid>
        </Grid>
        <Grid item lg={6}>
          <Grid
            container
            display={"flex"}
            direction={"row-reverse"}
            justifyContent="flex-start"
            pr={1}
            mt={0}
            //sx={{ border: "solid" }}
          >
            {/* <Grid item pl={{ xs: 0, lg: 1 }} pt={{ xs: 3, lg: 3 }} pr={{ xs: 0, lg: 2 }}>
              <img src={logo} alt="IA Logo" style={{ width: 300, height: 35 }} />
            </Grid> */}
            <Grid item pl={{ xs: 3, lg: 1 }} pt={{ xs: 0, lg: 5 }} pr={{ lg: 2 }} >
              <Typography fontFamily="Segoe UI" fontSize={16}>
                {" "}
                Report Last Updated :- {rlatest_date}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default SyndicatePortfolio;