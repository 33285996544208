import React, { useCallback, useEffect, useState } from "react";
import Iangel from "./iangel";
import { useNavigate } from "react-router";

const NewInvestorPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate()
    const investorData = JSON.parse(localStorage.getItem("investorData"));
    const [investmentData, setInvestmentData] = useState([]);

    const checkIfUserIsLoggedIn = useCallback(async()=>{
        const investorData = JSON.parse(localStorage.getItem("investorData"))
        if(!investorData?.jwt){
            navigate("/login")
            return
        }
    },[investorData?.jwt])

    // fetch here and pass the data to the child 
    // const fetchInvestorData = useCallback(async()=>{
    //     const investorData = JSON.parse(localStorage.getItem("investorData"))
    //     console.log(investorData)
    //     const response = await fetch(`${API_BASE_URL}/api/iangels-investments/${investorData?.email}`,{headers: {
    //         Authorization: `Bearer ${investorData?.jwt}`, 
    //       }});
    //       const {data} = await response.json();
    //       setInvestmentData(data?.foundInvestmentData)
    //       console.log(data?.foundInvestmentData)
    // },[investorData?.jwt])


    useEffect(()=>{
        checkIfUserIsLoggedIn();
    },[checkIfUserIsLoggedIn])

    if(!investorData?.jwt){
        return
    }

  return (
    <>
      <Iangel/>
    </>
  );
};

export default NewInvestorPage;
