import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { Chart, ChartComponent } from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { Box, Button, Divider, Drawer, Grid, Icon, Link, Paper, Typography } from "@mui/material";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartPieChartOutlabels from "@energiency/chartjs-plugin-piechart-outlabels";
import { color } from 'chart.js/helpers';
import { left } from '@popperjs/core';
import axios from 'axios';
import { Navigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Height, SignalCellularNoSimOutlined } from "@mui/icons-material";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../icons/ialogo.png";
import favicon from "../icons/favicon.ico";
import { ExportDataToExcel } from '../components/ExcelExport';


Chart.register(ChartPieChartOutlabels)
Chart.register(TreemapController, TreemapElement);
Chart.register(MatrixController, MatrixElement);


// const useStyles = makeStyles({
//   positive: {
//     color: 'green',
//   },
//   negative: {
//     color: 'red',
//   },
// });

function SyndicateAccess() {
  // const classes = useStyles();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  
  // Data table for Investor
  const [originalData, setoriginalData] = useState([]); // Original data loaded for the logged on Investor
  const [v1inv, setv1inv] = useState([]); // Copy of the original data on which the data is loaded


   // Measures calculated out of the v1inv data
   const [total_inv, settotal_inv] = useState([]);
   const [current_value, setcurrent_value] = useState([]);
   const [pnl, setpnl] = useState([]);
   const [xreturns, setxreturns] = useState([]);
   const [startupscovered, setstartupscovered] = useState([]);
   const [rlatest_date, setrlatest_date] = useState([]);
   const [iangname, setiangname] = useState([]);
   const [iangid, setiangid] = useState([]);
   const [current_date, setcurrent_date] = useState([]);
   //data for table
   const [tdata, settdata] = useState([]);

   // for button click - doughnut chart
   const [displayText, setDisplayText] = useState('Current Value of Holdings - Startup Wise');

// for button click - treemap chart
   const [treemapdisplayText, settreemapdisplayText] = useState('Current Value of Holdings');

   // for horizontal bar charts
   const [startupwisehbData, setstartupwisehbData] = useState(null);
   const [domainwisehbData, setdomainwisehbData] = useState(null);
   // for horizontal bar chart
  const [chartData, setChartData] = useState(null);
  const [chartHeight, setChartHeight] = useState(null);
  const [chartHeight2, setChartHeight2] = useState(null);
  const [SUDomainSort, setSUDomainSort] = useState(false)
  const [startupNameSort, setStartupNameSort] = useState(false)
  const [CvalSort, setCvalSort] = useState(false);
  const [ivalSort, setivalSort] = useState(false);
  const [pnlSort, setPnLSort] = useState(false);
  const [CvalStartupSort, setCvalStartupSort] = useState(false);
  const [tableHeaderHover, setTableHeaderHover] = useState({domain:false,name:false,cval:false,ival:false,pnl:false,cvalStartup:false, iprice:false, Cpricepershare:false,pre_money_valuation_startup:false});
  const [sort, setSort] = useState({domain:null,name:null,cval:false,ival:null,pnl:null,cvalStartup:null, iprice:null, Cpricepershare:null, pre_money_valuation_startup:null});
  const param = useParams()

  // for filter selection menu
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  //_____________________________________

  // Logout link function
  const [logoutData, setlogoutData] = useState(null);
  const handleLinkClickiang = async () => {
    try {
      localStorage.removeItem("investorData")
      Navigate("/login")
    } catch (error) {
      console.error(error);
    }
  };


  let val = 0;

  useEffect(() => {
    async function fetchData() {
      const investorData = JSON.parse(localStorage.getItem("investorData"));
      // one set of data for all measures and visuals for investors
      const responsev1inv = await fetch(`${API_BASE_URL}/api/iangels-syndicate-access/${param?.investorEmail}`);
      const {data} = await responsev1inv.json();
      
      const convertCrValuesInLakhs = data?.foundInvestmentData?.map((ele)=>{
        return {...ele, Cval_cr: Number(ele.Cval_cr)*100, Cval_startup_cr: Number(ele.Cval_startup_cr)*100, ival_cr: Number(ele.ival_cr)*100, pnl_cr: Number(ele.pnl_cr)*100, pre_money_valuation_startup:Number(ele.pre_money_valuation_startup)/100000}
      })
      // console.log(convertCrValuesInLakhs);

      const v1inv = convertCrValuesInLakhs;
      setv1inv(v1inv);
      setoriginalData(v1inv);   

      // Calculations for the Measures below
      // Total Investments
      const sumIvalcr = v1inv.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.ival_cr);
      }, 0);
      settotal_inv(sumIvalcr);      

      // Current Value Total
      const sumCvalcr = v1inv.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.Cval_cr);
      }, 0); 
      setcurrent_value(sumCvalcr);      

      // Unrealized P&L Value Total
      const pnl = v1inv.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.pnl_cr);
      }, 0);
      setpnl(pnl);      

      // Startups Covered
      const distinctSUCount = [...new Set(v1inv.map((investment) => investment.startupname))].length;
      setstartupscovered(distinctSUCount);      

      // IAM Name
      const iangname = [...new Set(v1inv.map((item) => item.Investor))];
      setiangname(iangname);      

      // Investor ID
      const iangid = [...new Set(v1inv.map((item) => item.ianglogin))];
      setiangid(iangid);      
      
      //rlatest_date
      const rlatest_date = v1inv?.reduce((acc, curr) => {
        return acc.last_updated > curr.last_updated ? acc : curr;
      },0).last_updated;
      
      const dateObj = new Date(rlatest_date);
      const day = dateObj?.getDate();
      const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);
      const year = dateObj?.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      
      setrlatest_date(formattedDate);

      //current_time_am_pm
      const dateObj2 = new Date();
      const day2 = dateObj.getDate();
      const month2 = dateObj.toLocaleString("default", { month: "short" });
      const year2 = dateObj.getFullYear();
      setcurrent_date(`${day}-${month}-${year}`);

      

      // sort in descending order based on Cval_cr
      settdata(
        [...v1inv].sort((a, b) => {
          return b.Cval_cr - a.Cval_cr;
        })
      );

      const groupedDomain = v1inv.reduce((acc, stage) => {
        const { SU_Domain, Cval_cr } = stage;
        if (acc[SU_Domain]) {
          acc[SU_Domain] += Number(Cval_cr);
        } else {
          acc[SU_Domain] = Number(Cval_cr);
        }
        return acc;
      }, {});
    
      const groupedStartups = v1inv.reduce((acc, stage) => {
        const { startupname, Cval_cr } = stage;
        if (acc[startupname]) {
          acc[startupname] += Number(Cval_cr);
        } else {
          acc[startupname] = Number(Cval_cr);
        }
        return acc;
      }, {});

      const groupedStage = v1inv.reduce((acc, stge) => {
        const { stage, Cval_cr } = stge;
        if (acc[stage]) {
          acc[stage] += Number(Cval_cr);
        } else {
          acc[stage] = Number(Cval_cr);
        }
        return acc;
      }, {});

      const dataaa4 = {
        labels: Object.keys(groupedStage),
        datasets: [
          {
            data: Object.values(groupedStage),
            backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
          },
        ],
      };


      const dataaa3 = {
        labels: Object.keys(groupedDomain),
        datasets: [
          {
            data: Object.values(groupedDomain),
            backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
          },
        ],
      };
      
      const dataaa2 = {
        labels: Object.keys(groupedStartups),
        datasets: [
          {
            data: Object.values(groupedStartups),
            backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
          },
        ],
      };
      setPiechartData(dataaa2);

      // treemap data for startups/cval
const tmgroupedcval = v1inv.map((obj) => {
  return { startupname: obj.startupname, Cval_cr: Number(obj.Cval_cr).toFixed(2) };
}).sort((a, b) => b.Cval_cr - a.Cval_cr); 



const tmdataaa2 = {
  datasets: [
    {
      tree: tmgroupedcval,
      key: 'Cval_cr',
      groups: ['startupname'],
      backgroundColor: (ctx) => getColor(ctx),
      // datalabels: {
      //   display: 'true',
      //   anchor: 'start',
      //   align: 45,
      //   formatter: (value) => `${value.g}` + `\n₹${value.v.toFixed(2)}L`,
      //   color: 'black',
      //   font: {
      //     size: 12,
      //     weight: 'bold'
      //   }
      // },
      // labels: {
      //   display: true,
      //   padding : 2, 
      //   align: 'left',
      //   position: 'top',        
      //   font: {
      //     family: 'Segoe UI',
      //     weight: 'bold',
      //     size: 12
      //   },
      //   formatter: (ctx) => [`${ctx.raw.g}`," ",`₹${ctx.raw.v.toFixed(2)} L`]
      //   // formatter: (ctx) => ["first line", "second line"]
      //   // children: [
      //   //   {
      //   //     display: true,
      //   //     align: 'left',
      //   //     position: 'bottom',
      //   //     formatter: (ctx) => `₹${ctx.raw.v} L`
      //   //   }
      //   // ]
      // }
    },
  ],
}


settreemapData(tmdataaa2)

//horizontal bar charts data

// data for startups 
  // Sort the data in descending order by Cval_cr
  const sortedData = v1inv.sort((a, b) => parseFloat(b.Cval_cr) - parseFloat(a.Cval_cr));

  // Extract the required labels and data for the chart
  const labels = sortedData.map(item => item.startupname);  
  const amountInvestedData = sortedData.map(item => parseFloat(item.ival_cr).toFixed(2));
  const unrealizedPnlData = sortedData.map(item => parseFloat(item.pnl_cr).toFixed(2));

  // Define the chart datasets
  const datasets = [
    {
      label: 'Amount Invested',
      data: amountInvestedData,
      backgroundColor: '#3281B7',
    },
    {
      label: 'Unrealized P&L',
      data: unrealizedPnlData,
      backgroundColor: '#2E927C',
    },
  ];

  const startupwisehbData = {
    labels,
    datasets,
  }; 

  


  
    // Group the data by SU_Domain and sum the ival_cr and pnl_cr values
    // const groupedData2 = v1inv.reduce((acc, item) => {
    //   const { SU_Domain, ival_cr, pnl_cr } = item;
    //   if (!acc[SU_Domain]) {
    //     acc[SU_Domain] = {
    //       ival_cr: 0,
    //       pnl_cr: 0
    //     };
    //   }
    //   acc[SU_Domain].ival_cr += parseFloat(ival_cr);
    //   acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
    //   return acc;
    // }, {});

    const groupedData2 = v1inv.reduce((acc, item) => {
      const { SU_Domain, ival_cr, pnl_cr, cval_cr } = item;
      if (!acc[SU_Domain]) {
        acc[SU_Domain] = {
          ival_cr: 0,
          pnl_cr: 0,
          cval_cr: 0
        };
      }
      acc[SU_Domain].ival_cr += parseFloat(ival_cr);
      acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
      acc[SU_Domain].cval_cr = parseFloat(cval_cr);
      return acc;
    }, {});

    

// Sort the grouped data by Cval_cr in descending order
const sortedData2 = Object.entries(groupedData2).sort(([, a], [, b]) => parseFloat(b.cval_cr) - parseFloat(a.cval_cr));

// Extract the required labels and data for the chart
const labels2 = sortedData2.map(([SU_Domain]) => SU_Domain);
const amountInvestedData2 = sortedData2.map(([, data]) => data.ival_cr.toFixed(2));
const unrealizedPnlData2 = sortedData2.map(([, data]) => data.pnl_cr.toFixed(2));

// Define the chart datasets
const datasets2 = [
  {
    label: 'Amount Invested',
    data: amountInvestedData,
    backgroundColor: '#3281B7',
  },
  {
    label: 'Unrealized P&L',
    data: unrealizedPnlData,
    backgroundColor: '#2E927C',
  },
];

const domainwisehbData = {
  labels:labels2,
  datasets:datasets2,
};
  


setstartupwisehbData(startupwisehbData)
setdomainwisehbData(domainwisehbData)



const numBars = startupwisehbData.labels.length;
const defaultHeight = 250;
const dynamicHeight = Math.max(numBars * 40, defaultHeight);
setChartHeight(dynamicHeight);

const numBars2 = domainwisehbData.labels.length;
const defaultHeight2 = 250;
const dynamicHeight2 = Math.max(numBars * 40, defaultHeight);
setChartHeight2(dynamicHeight2);



  }
    fetchData();
    return () => {
      
    };
  }, []);


  // checkbox for investment type
  const checks_invtype = [...new Set(originalData?.map((item) => item.share_type))];
  const [cinvtypeName, setcinvtypeName] = useState([]);
  const handleChangecinvtype = (event) => {
    const { value } = event.target;
    const selectedInvtype = Array.isArray(value) ? value : [value];
    setcinvtypeName(selectedInvtype);

    // Filter v1inv array by selected Invtype
    const filteredV1inv = selectedInvtype.length > 0 ? originalData?.filter((item) => selectedInvtype.includes(item.share_type)) : originalData;

    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };


  // checkbox for startups
  const checks_startup = [...new Set(originalData?.map((item) => item.startupname))];
  const [cstartupName, setcstartupName] = useState([]);
  const handleChangecstartup = (event) => {
    const { value } = event.target;
    const selectedStartups = Array.isArray(value) ? value : [value];
    setcstartupName(selectedStartups);

    // Filter v1inv array by selected funds
    const filteredV1inv = selectedStartups.length > 0 ? originalData?.filter((item) => selectedStartups.includes(item.startupname)) : originalData;

    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };

  // checkbox for Domain
  // const checks_domain = [...new Set(originalData.map((item) => item.SU_Domain))];
  const checks_domain = originalData?.filter((item, index, self) =>
  index === self.findIndex((t) => t.SU_Domain === item.SU_Domain)
).map(item => item.SU_Domain);
  const [cdomainName, setcdomainName] = useState([]);
  const handleChangecdomain = (event) => {
    const { value } = event.target;
    const selectedDomains = typeof value === "string" ? value.split(",") : value;
    setcdomainName(selectedDomains);

    // Filter v1inv array by selected Domains
    const filteredV1inv = selectedDomains.length > 0 ? originalData?.filter((item) => selectedDomains.includes(item.SU_Domain)) : originalData;

    
    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };


    // checkbox for Investor  
    const checks_investor = [...new Set(originalData?.map((item) => item.Investor))];
    const [cinvestorName, setcinvestorName] = useState([]);

    const handleChangecinvestor = (event) => {
    const { value } = event.target;
    const selectedInvestors = typeof value === "string" ? value.split(",") : value;
    setcinvestorName(selectedInvestors);

    // Filter v1inv array by selected investors
    const filteredV1inv = selectedInvestors.length > 0 ? originalData?.filter((item) => selectedInvestors.includes(item.Investor)) : originalData;
    console.log(JSON.stringify(filteredV1inv, null, 2));
    // Set v1inv state to filtered v1inv array
    setv1inv(filteredV1inv);
    
  };

// checkbox for Stage
  const checks_stage = [...new Set(originalData?.map((item) => item.SU_Domain))];
  const [cstageName, setcstageName] = useState([]);
  const handleChangecstage = (event) => {
  const { value } = event.target;
  const selectedstage = typeof value === "string" ? value.split(",") : value;
  setcstageName(selectedstage);

  // Filter v1inv array by selected funds
  const filteredV1inv = selectedstage.length > 0 ? originalData?.filter((item) => selectedstage.includes(item.stage)) : originalData;

  // Set v1inv state to filtered v1inv array
  setv1inv(filteredV1inv);
  
};

  useEffect(() => {    
    // Calculations for the Measures below

    // Total Investments
    const sumIvalcr = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.ival_cr);
    }, 0);
    settotal_inv(sumIvalcr );    

    // Current Value Total
    const sumCvalcr  = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.Cval_cr);
    }, 0);
    setcurrent_value(sumCvalcr);    

    // Unrealized P&L Value Total
    const pnl = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.pnl_cr);
    }, 0);
    setpnl(pnl);    

    // Startups Covered
    const distinctSUCount = [...new Set(v1inv.map((investment) => investment.startupname))].length;
    setstartupscovered(distinctSUCount);    

    // Investor Name
    const iangname = [...new Set(v1inv.map(item => item.Investor))];
    setiangname(iangname);    

    // Investor ID
    const iangid = [...new Set(v1inv.map(item => item.ianglogin))];
    setiangid(iangid);    

    //rlatest_date
    // const rlatest_date = v1inv.reduce((acc, curr) => {
    //   return acc.Cdate2 > curr.Cdate2 ? acc : curr;
    // }).last_updated;
    
    // const dateObj = new Date(rlatest_date);
    // const day = dateObj.getDate();
    // const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);
    // const year = dateObj.getFullYear();
    // const formattedDate = `${day}-${month}-${year}`;
    
    // setrlatest_date(formattedDate);
    

    //  arranged tdata in descending order
    settdata(
      [...v1inv].sort((a, b) => {
        return b.Cval_cr - a.Cval_cr;
      })
    );   
    
    const groupedDomain = v1inv.reduce((acc, stage) => {
      const { SU_Domain, Cval_cr } = stage;
      if (acc[SU_Domain]) {
        acc[SU_Domain] += Number(Cval_cr);
      } else {
        acc[SU_Domain] = Number(Cval_cr);
      }
      return acc;
    }, {});
  
    const groupedStartups = v1inv.reduce((acc, stage) => {
      const { startupname, Cval_cr } = stage;
      if (acc[startupname]) {
        acc[startupname] += Number(Cval_cr);
      } else {
        acc[startupname] = Number(Cval_cr);
      }
      return acc;
     }, {});

    const groupedStage = v1inv.reduce((acc, stge) => {
      const { stage, Cval_cr } = stge;
      if (acc[stage]) {
        acc[stage] += Number(Cval_cr);
      } else {
        acc[stage] = Number(Cval_cr);
      }
      return acc;
    }, {});

    const dataaa4 = {
      labels: Object.keys(groupedStage),
      datasets: [
        {
          data: Object.values(groupedStage),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    const dataaa3 = {
      labels: Object.keys(groupedDomain),
      datasets: [
        {
          data: Object.values(groupedDomain),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };
    
    const dataaa2 = {
      labels: Object.keys(groupedStartups),
      datasets: [
        {
          data: Object.values(groupedStartups),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    
    setPiechartData(dataaa2);
    setDisplayText('Current Value of Holdings - Startup Wise');

// treemap data for startups/cval
const tmgroupedcval = v1inv.map((obj) => {
  return { startupname: obj.startupname, Cval_cr: Number(obj.Cval_cr).toFixed(2) };
}).sort((a, b) => b.Cval_cr - a.Cval_cr); 

const tmdataaa2 = {    
  datasets: [
    {
      tree: tmgroupedcval,
      key: 'Cval_cr',
      groups: ['startupname'],
      backgroundColor: (ctx)=> getColor(ctx),
      // labels: {
      //   display: false,
      //   align: left,
      //   position:'top',         
      //   // formatter: (ctx) => ctx.raw.g,
      //   //formatter: (ctx) => `${ctx.raw.g}\n${ctx  .raw.v} L`,
      //   //formatter: (ctx) =>  ctx.raw.v + ' L'
      //   // children: [
      //   //   {
      //   //     display: true,
      //   //     align: 'left',
      //   //     position: 'bottom',
      //   //     formatter: (ctx) => ctx.raw.v + ' L'
      //   //   }
      //   // ]           
      // }
      //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]        
    },
  ],
};

settreemapData(tmdataaa2)

//horizontal bar charts data

// data for startups 
  // Sort the data in descending order by Cval_cr
  const sortedData = v1inv.sort((a, b) => parseFloat(b.Cval_cr) - parseFloat(a.Cval_cr));

  // Extract the required labels and data for the chart
  const labels = sortedData.map(item => item.startupname);  
  const amountInvestedData = sortedData.map(item => parseFloat(item.ival_cr).toFixed(2));
  const unrealizedPnlData = sortedData.map(item => parseFloat(item.pnl_cr).toFixed(2));

  // Define the chart datasets
  const datasets = [
    {
      label: 'Amount Invested',
      data: amountInvestedData,
      backgroundColor: '#3281B7',
    },
    {
      label: 'Unrealized P&L',
      data: unrealizedPnlData,
      backgroundColor: '#2E927C',
    },
  ];

  const startupwisehbData = {
    labels,
    datasets,
  }; 

  


  
    // Group the data by SU_Domain and sum the ival_cr and pnl_cr values
    // const groupedData2 = v1inv.reduce((acc, item) => {
    //   const { SU_Domain, ival_cr, pnl_cr } = item;
    //   if (!acc[SU_Domain]) {
    //     acc[SU_Domain] = {
    //       ival_cr: 0,
    //       pnl_cr: 0
    //     };
    //   }
    //   acc[SU_Domain].ival_cr += parseFloat(ival_cr);
    //   acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
    //   return acc;
    // }, {});

    const groupedData2 = v1inv.reduce((acc, item) => {
      const { SU_Domain, ival_cr, pnl_cr, cval_cr } = item;
      if (!acc[SU_Domain]) {
        acc[SU_Domain] = {
          ival_cr: 0,
          pnl_cr: 0,
          cval_cr: 0
        };
      }
      acc[SU_Domain].ival_cr += parseFloat(ival_cr);
      acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
      acc[SU_Domain].cval_cr = parseFloat(cval_cr);
      return acc;
    }, {});

    

// Sort the grouped data by Cval_cr in descending order
const sortedData2 = Object.entries(groupedData2).sort(([, a], [, b]) => parseFloat(b.cval_cr) - parseFloat(a.cval_cr));

// Extract the required labels and data for the chart
const labels2 = sortedData2.map(([SU_Domain]) => SU_Domain);
const amountInvestedData2 = sortedData2.map(([, data]) => data.ival_cr.toFixed(2));
const unrealizedPnlData2 = sortedData2.map(([, data]) => data.pnl_cr.toFixed(2));

// Define the chart datasets
const datasets2 = [
  {
    label: 'Amount Invested',
    data: amountInvestedData,
    backgroundColor: '#3281B7',
  },
  {
    label: 'Unrealized P&L',
    data: unrealizedPnlData,
    backgroundColor: '#2E927C',
  },
];

const domainwisehbData = {
  labels:labels2,
  datasets:datasets2,
};
  


setstartupwisehbData(startupwisehbData)
setdomainwisehbData(domainwisehbData)



const numBars = startupwisehbData.labels.length;
const defaultHeight = 250;
const dynamicHeight = Math.max(numBars * 40, defaultHeight);
setChartHeight(dynamicHeight);

const numBars2 = domainwisehbData.labels.length;
const defaultHeight2 = 250;
const dynamicHeight2 = Math.max(numBars * 40, defaultHeight);
setChartHeight2(dynamicHeight2);
  
  }, [cstartupName]);

  useEffect(() => {    
    // Calculations for the Measures below

    // Total Investments
    const sumIvalcr = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.ival_cr);
    }, 0);
    settotal_inv(sumIvalcr);    

    // Current Value Total
    const sumCvalcr = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.Cval_cr);
    }, 0);
    setcurrent_value(sumCvalcr);    

    // Unrealized P&L Value Total
    const pnl = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.pnl_cr);
    }, 0);
    setpnl(pnl);    

    // Startups Covered
    const distinctSUCount = [...new Set(v1inv.map((investment) => investment.startupname))].length;
    setstartupscovered(distinctSUCount);    

    // Investor Name
    const iangname = [...new Set(v1inv.map(item => item.Investor))];
    setiangname(iangname);    

    // Investor ID
    const iangid = [...new Set(v1inv.map(item => item.ianglogin))];
    setiangid(iangid);    

    //rlatest_date
    // const rlatest_date = v1inv.reduce((acc, curr) => {
    //   return acc.Cdate2 > curr.Cdate2 ? acc : curr;
    // }).last_updated;
    
    // const dateObj = new Date(rlatest_date);
    // const day = dateObj.getDate();
    // const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);
    // const year = dateObj.getFullYear();
    // const formattedDate = `${day}-${month}-${year}`;
    
    // setrlatest_date(formattedDate);
    

    //  arranged tdata in descending order
    settdata(
      [...v1inv].sort((a, b) => {
        return b.Cval_cr - a.Cval_cr;
      })
    );   
    
    const groupedDomain = v1inv.reduce((acc, stage) => {
      const { SU_Domain, Cval_cr } = stage;
      if (acc[SU_Domain]) {
        acc[SU_Domain] += Number(Cval_cr);
      } else {
        acc[SU_Domain] = Number(Cval_cr);
      }
      return acc;
    }, {});
  
    const groupedStartups = v1inv.reduce((acc, stage) => {
      const { startupname, Cval_cr } = stage;
      if (acc[startupname]) {
        acc[startupname] += Number(Cval_cr);
      } else {
        acc[startupname] = Number(Cval_cr);
      }
      return acc;     

    }, {});

    const groupedStage = v1inv.reduce((acc, stge) => {
      const { stage, Cval_cr } = stge;
      if (acc[stage]) {
        acc[stage] += Number(Cval_cr);
      } else {
        acc[stage] = Number(Cval_cr);
      }
      return acc;
    }, {});

    const dataaa4 = {
      labels: Object.keys(groupedStage),
      datasets: [
        {
          data: Object.values(groupedStage),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    const dataaa3 = {
      labels: Object.keys(groupedDomain),
      datasets: [
        {
          data: Object.values(groupedDomain),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };
    
    const dataaa2 = {
      labels: Object.keys(groupedStartups),
      datasets: [
        {
          data: Object.values(groupedStartups),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    
    setPiechartData(dataaa3);
    setDisplayText('Current Value of Holdings - Startup Wise');

// treemap data for startups/cval
const tmgroupedcval = v1inv.map((obj) => {
  return { startupname: obj.startupname, Cval_cr: Number(obj.Cval_cr).toFixed(2) };
}).sort((a, b) => b.Cval_cr - a.Cval_cr); 

const tmdataaa2 = {    
  datasets: [
    {
      tree: tmgroupedcval,
      key: 'Cval_cr',
      groups: ['startupname'],
      backgroundColor: (ctx)=> getColor(ctx),
      // labels: {
      //   display: false,
      //   align: left,
      //   position:'top',         
      //   // formatter: (ctx) => ctx.raw.g,
      //   //formatter: (ctx) => `${ctx.raw.g}\n${ctx  .raw.v} L`,
      //   //formatter: (ctx) =>  ctx.raw.v + ' L'
      //   // children: [
      //   //   {
      //   //     display: true,
      //   //     align: 'left',
      //   //     position: 'bottom',
      //   //     formatter: (ctx) => ctx.raw.v + ' L'
      //   //   }
      //   // ]           
      // }
      //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]        
    },
  ],
};

settreemapData(tmdataaa2)


//horizontal bar charts data

// data for startups 
  // Sort the data in descending order by Cval_cr
  const sortedData = v1inv.sort((a, b) => parseFloat(b.Cval_cr) - parseFloat(a.Cval_cr));

  // Extract the required labels and data for the chart
  const labels = sortedData.map(item => item.startupname);  
  const amountInvestedData = sortedData.map(item => parseFloat(item.ival_cr).toFixed(2));
  const unrealizedPnlData = sortedData.map(item => parseFloat(item.pnl_cr).toFixed(2));

  // Define the chart datasets
  const datasets = [
    {
      label: 'Amount Invested',
      data: amountInvestedData,
      backgroundColor: '#3281B7',
    },
    {
      label: 'Unrealized P&L',
      data: unrealizedPnlData,
      backgroundColor: '#2E927C',
    },
  ];

  const startupwisehbData = {
    labels,
    datasets,
  }; 

  


  
    // Group the data by SU_Domain and sum the ival_cr and pnl_cr values
    // const groupedData2 = v1inv.reduce((acc, item) => {
    //   const { SU_Domain, ival_cr, pnl_cr } = item;
    //   if (!acc[SU_Domain]) {
    //     acc[SU_Domain] = {
    //       ival_cr: 0,
    //       pnl_cr: 0
    //     };
    //   }
    //   acc[SU_Domain].ival_cr += parseFloat(ival_cr);
    //   acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
    //   return acc;
    // }, {});

    const groupedData2 = v1inv.reduce((acc, item) => {
      const { SU_Domain, ival_cr, pnl_cr, cval_cr } = item;
      if (!acc[SU_Domain]) {
        acc[SU_Domain] = {
          ival_cr: 0,
          pnl_cr: 0,
          cval_cr: 0
        };
      }
      acc[SU_Domain].ival_cr += parseFloat(ival_cr);
      acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
      acc[SU_Domain].cval_cr = parseFloat(cval_cr);
      return acc;
    }, {});

    

// Sort the grouped data by Cval_cr in descending order
const sortedData2 = Object.entries(groupedData2).sort(([, a], [, b]) => parseFloat(b.cval_cr) - parseFloat(a.cval_cr));

// Extract the required labels and data for the chart
const labels2 = sortedData2.map(([SU_Domain]) => SU_Domain);
const amountInvestedData2 = sortedData2.map(([, data]) => data.ival_cr.toFixed(2));
const unrealizedPnlData2 = sortedData2.map(([, data]) => data.pnl_cr.toFixed(2));

// Define the chart datasets
const datasets2 = [
  {
    label: 'Amount Invested',
    data: amountInvestedData,
    backgroundColor: '#3281B7',
  },
  {
    label: 'Unrealized P&L',
    data: unrealizedPnlData,
    backgroundColor: '#2E927C',
  },
];

const domainwisehbData = {
  labels:labels2,
  datasets:datasets2,
};
  


setstartupwisehbData(startupwisehbData)
setdomainwisehbData(domainwisehbData)



const numBars = startupwisehbData.labels.length;
const defaultHeight = 250;
const dynamicHeight = Math.max(numBars * 40, defaultHeight);
setChartHeight(dynamicHeight);

const numBars2 = domainwisehbData.labels.length;
const defaultHeight2 = 250;
const dynamicHeight2 = Math.max(numBars * 40, defaultHeight);
setChartHeight2(dynamicHeight2);


  }, [cdomainName]);

  useEffect(() => {    
    // Calculations for the Measures below

    // Total Investments
    const sumIvalcr = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.ival_cr);
    }, 0);
    settotal_inv(sumIvalcr);    

    // Current Value Total
    const sumCvalcr = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.Cval_cr);
    }, 0);
    setcurrent_value(sumCvalcr);    

    // Unrealized P&L Value Total
    const pnl = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.pnl_cr);
    }, 0);
    setpnl(pnl);    

    // Startups Covered
    const distinctSUCount = [...new Set(v1inv.map((investment) => investment.startupname))].length;
    setstartupscovered(distinctSUCount);    

    // Investor Name
    const iangname = [...new Set(v1inv.map(item => item.Investor))];
    setiangname(iangname);    

    // Investor ID
    const iangid = [...new Set(v1inv.map(item => item.ianglogin))];
    setiangid(iangid);    

    //rlatest_date
    // const rlatest_date = v1inv.reduce((acc, curr) => {
    //   return acc.Cdate2 > curr.Cdate2 ? acc : curr;
    // }).last_updated;
    
    // const dateObj = new Date(rlatest_date);
    // const day = dateObj.getDate();
    // const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);
    // const year = dateObj.getFullYear();
    // const formattedDate = `${day}-${month}-${year}`;
    
    // setrlatest_date(formattedDate);
    

    //  arranged tdata in descending order
    settdata(
      [...v1inv].sort((a, b) => {
        return b.Cval_cr - a.Cval_cr;
      })
    );   
    
    const groupedDomain = v1inv.reduce((acc, stage) => {
      const { SU_Domain, Cval_cr } = stage;
      if (acc[SU_Domain]) {
        acc[SU_Domain] += Number(Cval_cr);
      } else {
        acc[SU_Domain] = Number(Cval_cr);
      }
      return acc;
    }, {});
  
    const groupedStartups = v1inv.reduce((acc, stage) => {
      const { startupname, Cval_cr } = stage;
      if (acc[startupname]) {
        acc[startupname] += Number(Cval_cr);
      } else {
        acc[startupname] = Number(Cval_cr);
      }
      return acc;     

    }, {});

    const groupedStage = v1inv.reduce((acc, stge) => {
      const { stage, Cval_cr } = stge;
      if (acc[stage]) {
        acc[stage] += Number(Cval_cr);
      } else {
        acc[stage] = Number(Cval_cr);
      }
      return acc;
    }, {});

    const dataaa4 = {
      labels: Object.keys(groupedStage),
      datasets: [
        {
          data: Object.values(groupedStage),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    const dataaa3 = {
      labels: Object.keys(groupedDomain),
      datasets: [
        {
          data: Object.values(groupedDomain),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };
    
    const dataaa2 = {
      labels: Object.keys(groupedStartups),
      datasets: [
        {
          data: Object.values(groupedStartups),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    
    setPiechartData(dataaa3);
    setDisplayText('Current Value of Holdings - Startup Wise');

// treemap data for startups/cval
const tmgroupedcval = v1inv.map((obj) => {
  return { startupname: obj.startupname, Cval_cr: Number(obj.Cval_cr).toFixed(2) };
}).sort((a, b) => b.Cval_cr - a.Cval_cr); 

const tmdataaa2 = {    
  datasets: [
    {
      tree: tmgroupedcval,
      key: 'Cval_cr',
      groups: ['startupname'],
      backgroundColor: (ctx)=> getColor(ctx),
      // labels: {
      //   display: false,
      //   align: left,
      //   position:'top',         
      //   // formatter: (ctx) => ctx.raw.g,
      //   //formatter: (ctx) => `${ctx.raw.g}\n${ctx  .raw.v} L`,
      //   //formatter: (ctx) =>  ctx.raw.v + ' L'
      //   // children: [
      //   //   {
      //   //     display: true,
      //   //     align: 'left',
      //   //     position: 'bottom',
      //   //     formatter: (ctx) => ctx.raw.v + ' L'
      //   //   }
      //   // ]           
      // }
      //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]        
    },
  ],
};

settreemapData(tmdataaa2)


//horizontal bar charts data

// data for startups 
  // Sort the data in descending order by Cval_cr
  const sortedData = v1inv.sort((a, b) => parseFloat(b.Cval_cr) - parseFloat(a.Cval_cr));

  // Extract the required labels and data for the chart
  const labels = sortedData.map(item => item.startupname);  
  const amountInvestedData = sortedData.map(item => parseFloat(item.ival_cr).toFixed(2));
  const unrealizedPnlData = sortedData.map(item => parseFloat(item.pnl_cr).toFixed(2));

  // Define the chart datasets
  const datasets = [
    {
      label: 'Amount Invested',
      data: amountInvestedData,
      backgroundColor: '#3281B7',
    },
    {
      label: 'Unrealized P&L',
      data: unrealizedPnlData,
      backgroundColor: '#2E927C',
    },
  ];

  const startupwisehbData = {
    labels,
    datasets,
  }; 

  


  
    // Group the data by SU_Domain and sum the ival_cr and pnl_cr values
    // const groupedData2 = v1inv.reduce((acc, item) => {
    //   const { SU_Domain, ival_cr, pnl_cr } = item;
    //   if (!acc[SU_Domain]) {
    //     acc[SU_Domain] = {
    //       ival_cr: 0,
    //       pnl_cr: 0
    //     };
    //   }
    //   acc[SU_Domain].ival_cr += parseFloat(ival_cr);
    //   acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
    //   return acc;
    // }, {});

    const groupedData2 = v1inv.reduce((acc, item) => {
      const { SU_Domain, ival_cr, pnl_cr, cval_cr } = item;
      if (!acc[SU_Domain]) {
        acc[SU_Domain] = {
          ival_cr: 0,
          pnl_cr: 0,
          cval_cr: 0
        };
      }
      acc[SU_Domain].ival_cr += parseFloat(ival_cr);
      acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
      acc[SU_Domain].cval_cr = parseFloat(cval_cr);
      return acc;
    }, {});

    

// Sort the grouped data by Cval_cr in descending order
const sortedData2 = Object.entries(groupedData2).sort(([, a], [, b]) => parseFloat(b.cval_cr) - parseFloat(a.cval_cr));

// Extract the required labels and data for the chart
const labels2 = sortedData2.map(([SU_Domain]) => SU_Domain);
const amountInvestedData2 = sortedData2.map(([, data]) => data.ival_cr.toFixed(2));
const unrealizedPnlData2 = sortedData2.map(([, data]) => data.pnl_cr.toFixed(2));

// Define the chart datasets
const datasets2 = [
  {
    label: 'Amount Invested',
    data: amountInvestedData,
    backgroundColor: '#3281B7',
  },
  {
    label: 'Unrealized P&L',
    data: unrealizedPnlData,
    backgroundColor: '#2E927C',
  },
];

const domainwisehbData = {
  labels:labels2,
  datasets:datasets2,
};
  


setstartupwisehbData(startupwisehbData)
setdomainwisehbData(domainwisehbData)



const numBars = startupwisehbData.labels.length;
const defaultHeight = 250;
const dynamicHeight = Math.max(numBars * 40, defaultHeight);
setChartHeight(dynamicHeight);

const numBars2 = domainwisehbData.labels.length;
const defaultHeight2 = 250;
const dynamicHeight2 = Math.max(numBars * 40, defaultHeight);
setChartHeight2(dynamicHeight2);


  }, [cinvestorName]);


  useEffect(() => {    
    // Calculations for the Measures below

    // Total Investments
    const sumIvalcr = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.ival_cr);
    }, 0);
    settotal_inv(sumIvalcr);    

    // Current Value Total
    const sumCvalcr = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.Cval_cr);
    }, 0);
    setcurrent_value(sumCvalcr);    

    // Unrealized P&L Value Total
    const pnl = v1inv.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.pnl_cr);
    }, 0);
    setpnl(pnl);    

    // Startups Covered
    const distinctSUCount = [...new Set(v1inv.map((investment) => investment.startupname))].length;
    setstartupscovered(distinctSUCount);    

    // Investor Name
    const iangname = [...new Set(v1inv.map(item => item.Investor))];
    setiangname(iangname);    

    // Investor ID
    const iangid = [...new Set(v1inv.map(item => item.ianglogin))];
    setiangid(iangid);    

    //rlatest_date
    // const rlatest_date = v1inv.reduce((acc, curr) => {
    //   return acc.Cdate2 > curr.Cdate2 ? acc : curr;
    // }).last_updated;
    
    // const dateObj = new Date(rlatest_date);
    // const day = dateObj.getDate();
    // const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);
    // const year = dateObj.getFullYear();
    // const formattedDate = `${day}-${month}-${year}`;
    
    // setrlatest_date(formattedDate);
    

    //  arranged tdata in descending order
    settdata(
      [...v1inv].sort((a, b) => {
        return b.Cval_cr - a.Cval_cr;
      })
    );   
    
    const groupedDomain = v1inv.reduce((acc, stage) => {
      const { SU_Domain, Cval_cr } = stage;
      if (acc[SU_Domain]) {
        acc[SU_Domain] += Number(Cval_cr);
      } else {
        acc[SU_Domain] = Number(Cval_cr);
      }
      return acc;
    }, {});
  
    const groupedStartups = v1inv.reduce((acc, stage) => {
      const { startupname, Cval_cr } = stage;
      if (acc[startupname]) {
        acc[startupname] += Number(Cval_cr);
      } else {
        acc[startupname] = Number(Cval_cr);
      }
      return acc;     

    }, {});

    const groupedStage = v1inv.reduce((acc, stge) => {
      const { stage, Cval_cr } = stge;
      if (acc[stage]) {
        acc[stage] += Number(Cval_cr);
      } else {
        acc[stage] = Number(Cval_cr);
      }
      return acc;
    }, {});

    const dataaa4 = {
      labels: Object.keys(groupedStage),
      datasets: [
        {
          data: Object.values(groupedStage),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    const dataaa3 = {
      labels: Object.keys(groupedDomain),
      datasets: [
        {
          data: Object.values(groupedDomain),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };
    
    const dataaa2 = {
      labels: Object.keys(groupedStartups),
      datasets: [
        {
          data: Object.values(groupedStartups),
          backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
        },
      ],
    };

    
    setPiechartData(dataaa3);
    setDisplayText('Current Value of Holdings - Startup Wise');

// treemap data for startups/cval
const tmgroupedcval = v1inv.map((obj) => {
  return { startupname: obj.startupname, Cval_cr: Number(obj.Cval_cr).toFixed(2) };
}).sort((a, b) => b.Cval_cr - a.Cval_cr); 

const tmdataaa2 = {    
  datasets: [
    {
      tree: tmgroupedcval,
      key: 'Cval_cr',
      groups: ['startupname'],
      backgroundColor: (ctx)=> getColor(ctx),
      // labels: {
      //   display: false,
      //   align: left,
      //   position:'top',         
      //   // formatter: (ctx) => ctx.raw.g,
      //   //formatter: (ctx) => `${ctx.raw.g}\n${ctx  .raw.v} L`,
      //   //formatter: (ctx) =>  ctx.raw.v + ' L'
      //   // children: [
      //   //   {
      //   //     display: true,
      //   //     align: 'left',
      //   //     position: 'bottom',
      //   //     formatter: (ctx) => ctx.raw.v + ' L'
      //   //   }
      //   // ]           
      // }
      //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]        
    },
  ],
};

settreemapData(tmdataaa2)


//horizontal bar charts data

// data for startups 
  // Sort the data in descending order by Cval_cr
  const sortedData = v1inv.sort((a, b) => parseFloat(b.Cval_cr) - parseFloat(a.Cval_cr));

  // Extract the required labels and data for the chart
  const labels = sortedData.map(item => item.startupname);  
  const amountInvestedData = sortedData.map(item => parseFloat(item.ival_cr).toFixed(2));
  const unrealizedPnlData = sortedData.map(item => parseFloat(item.pnl_cr).toFixed(2));

  // Define the chart datasets
  const datasets = [
    {
      label: 'Amount Invested',
      data: amountInvestedData,
      backgroundColor: '#3281B7',
    },
    {
      label: 'Unrealized P&L',
      data: unrealizedPnlData,
      backgroundColor: '#2E927C',
    },
  ];

  const startupwisehbData = {
    labels,
    datasets,
  }; 

  


  
    // Group the data by SU_Domain and sum the ival_cr and pnl_cr values
    // const groupedData2 = v1inv.reduce((acc, item) => {
    //   const { SU_Domain, ival_cr, pnl_cr } = item;
    //   if (!acc[SU_Domain]) {
    //     acc[SU_Domain] = {
    //       ival_cr: 0,
    //       pnl_cr: 0
    //     };
    //   }
    //   acc[SU_Domain].ival_cr += parseFloat(ival_cr);
    //   acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
    //   return acc;
    // }, {});

    const groupedData2 = v1inv.reduce((acc, item) => {
      const { SU_Domain, ival_cr, pnl_cr, cval_cr } = item;
      if (!acc[SU_Domain]) {
        acc[SU_Domain] = {
          ival_cr: 0,
          pnl_cr: 0,
          cval_cr: 0
        };
      }
      acc[SU_Domain].ival_cr += parseFloat(ival_cr);
      acc[SU_Domain].pnl_cr += parseFloat(pnl_cr);
      acc[SU_Domain].cval_cr = parseFloat(cval_cr);
      return acc;
    }, {});

    

// Sort the grouped data by Cval_cr in descending order
const sortedData2 = Object.entries(groupedData2).sort(([, a], [, b]) => parseFloat(b.cval_cr) - parseFloat(a.cval_cr));

// Extract the required labels and data for the chart
const labels2 = sortedData2.map(([SU_Domain]) => SU_Domain);
const amountInvestedData2 = sortedData2.map(([, data]) => data.ival_cr.toFixed(2));
const unrealizedPnlData2 = sortedData2.map(([, data]) => data.pnl_cr.toFixed(2));

// Define the chart datasets
const datasets2 = [
  {
    label: 'Amount Invested',
    data: amountInvestedData,
    backgroundColor: '#3281B7',
  },
  {
    label: 'Unrealized P&L',
    data: unrealizedPnlData,
    backgroundColor: '#2E927C',
  },
];

const domainwisehbData = {
  labels:labels2,
  datasets:datasets2,
};
  


setstartupwisehbData(startupwisehbData)
setdomainwisehbData(domainwisehbData)



const numBars = startupwisehbData.labels.length;
const defaultHeight = 250;
const dynamicHeight = Math.max(numBars * 40, defaultHeight);
setChartHeight(dynamicHeight);

const numBars2 = domainwisehbData.labels.length;
const defaultHeight2 = 250;
const dynamicHeight2 = Math.max(numBars * 40, defaultHeight);
setChartHeight2(dynamicHeight2);


  }, [cinvtypeName]);


  // below for dropdowns

  const ITEM_HEIGHT = 158;
  const ITEM_PADDING_TOP = 1;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };  

   function getColor(ctx) { 
    const colors = ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#E87934","#666666","#3599B8","#DFBFBF","#4AC5BB","#5F6B6D","#F4D25A","#7F898A","#A4DDEE","#FDAB89"];    
    if (ctx.type==='data') {
      return colors[ctx.raw._data._idx];  
    }
   else return 'Black';
 }
    
  const bgColors = ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"];
  
  const groupedDomain = v1inv.reduce((acc, stage) => {
    const { SU_Domain, Cval_cr } = stage;
    if (acc[SU_Domain]) {
      acc[SU_Domain] += Number(Cval_cr);
    } else {
      acc[SU_Domain] = Number(Cval_cr);
    }
    return acc;
  }, {});

  const groupedStartups = v1inv.reduce((acc, stage) => {
    const { startupname, Cval_cr } = stage;
    if (acc[startupname]) {
      acc[startupname] += Number(Cval_cr);
    } else {
      acc[startupname] = Number(Cval_cr);
    }
    return acc;
  }, {});

  const groupedStage = v1inv.reduce((acc, stge) => {
    const { stage, Cval_cr } = stge;
    if (acc[stage]) {
      acc[stage] += Number(Cval_cr);
    } else {
      acc[stage] = Number(Cval_cr);
    }
    return acc;
  }, {});

  const dataaa4 = {
    labels: Object.keys(groupedStage),
    datasets: [
      {
        data: Object.values(groupedStage),
        backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
      },
    ],
  };

  const dataaa3 = {
    labels: Object.keys(groupedDomain),
    datasets: [
      {
        data: Object.values(groupedDomain),
        backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
      },
    ],
  };
  
  const dataaa2 = {
    labels: Object.keys(groupedStartups),
    datasets: [
      {
        data: Object.values(groupedStartups),
        backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
      },
    ],
  };
  

// const dataaa2 = {
//   labels: v1inv.map((stage) => stage.startupname),
//   datasets: [
//     {
//       data: v1inv.map((stage) => Number(stage.Cval_cr)),
//       backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
//     },
//   ],
// };

// const sampledata = {
//   "labels": [
//     "Fretbox",
//     "Debox",
//     "Farmology",
//     "Cleanomatics",
//     "Zaiko",
//     "CyberEra",
//     "Jammin",
//     "Aaiena",
//     "Aromica",
//     "Innotech Interventions",
//     "P2P  Carz",
//     "One NDF",
//     "Hoppy Mobility",
//     "PWIP Food tech",
//     "Agecetra",
//     "Podhauz",
//     "High Tournament",
//     "Legodesk",
//     "Dbaitclub",
//     "Giverly"
//   ],
//   "datasets": [
//     {
//       "data": [
//         5.00387,
//         5.0005,
//         5.00018,
//         5.0000041,
//         5.000003599999999,
//         4.99996,
//         5.0005,
//         4.9984,
//         4.99904,
//         4.99944,
//         5.0005,
//         5.00084,
//         4.99995,
//         5.0002,
//         4.99995,
//         4.99944,
//         5,
//         5.0005,
//         5.0005,
//         10.564
//       ],
//       "backgroundColor": [
//         "#3281B7",
//         "#2E927C",
//         "#EF9921",
//         "#7EA8C2",
//         "#73A59C",
//         "#F7CC8B",
//         "#3281B7",
//         "#2E927C",
//         "#EF9921",
//         "#7EA8C2",
//         "#73A59C",
//         "#F7CC8B"
//       ]
//     }
//   ]
// }

const [piechartData, setPiechartData] = useState(dataaa2);
const piechartRef = useRef();
const piechartInstance = useRef(null);

const initializeChart = () => {
  const mypiechartRef = piechartRef.current.getContext('2d');
  if (piechartInstance.current) {
    piechartInstance.current.destroy();
  }  
  piechartInstance.current = new Chart(mypiechartRef, {
    type: 'doughnut',
    data: piechartData,
    options: pie_options,
    plugins: [ChartPieChartOutlabels,ChartDataLabels]
  });
};

useEffect(() => {
  
  
  initializeChart();

  return () => {        
    // if (piechartInstance.current) {
    //   piechartInstance.current.destroy();
    // }
    
  };
}, [piechartData, v1inv]);



useEffect(() => {
  const resizeHandler = () => {
    initializeChart();
  };

  window.addEventListener("resize", resizeHandler);

  // Clean up the event listener on component unmount
  return () => {
    window.removeEventListener("resize", resizeHandler);
  };
}, [piechartData, v1inv]);


const updateChartData1 = () => {
  setPiechartData(dataaa2);
};

const updateChartData2 = () => {
  setPiechartData(dataaa3);
};

const updateChartData3 = () => {
  setPiechartData(dataaa4);
};

 
  // pie options  
    const pie_options = {
      // animations: true,  
      // cutout: "60%",
      // circumference: 10,
      radius: "60%",
      layout: {
        padding: 35,
      },
      // zoomOutPercentage: "40",
      plugins: {      
        datalabels: {
        align: 'end',
        anchor: 'center',
        // backgroundColor: 'black',
        borderRadius:'5',
        display: false,
        color:'black',
        formatter:(value,context) => {
          return context.chart.data.labels[context.dataIndex] + ': '+ `${value}%`;
        }
        },
        outlabels: {
          display: true,        
          // text: "%l \n₹%v.2L (%p.2) ",
          text: "%l ₹%v.2 L",
          color: "black",
          // lineColor: "#4C4A48",
          lineWidth: "1",        
          stretch: 1,
          font: {
            resizable: true,
            minSize: 12,
				    maxSize: 16,
            family: "Segoe UI",
            // size: "12",
          },
          // backgroundColor: false,
          // formatter: function (value, context) {
          //   var percentage = context.dataset.percentages[context.dataIndex];
          //   if (percentage < 5) {
          //     return '';
          //   } else {
          //     return value;
          //   }
          // }
        },
        
        tooltip: {
          enabled: true,
          backgroundColor: "white",
          bodyColor: "Black",
          titleColor: "Black",
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              var value = context.dataset.data[context.dataIndex];
              var total = context.dataset.data.reduce((acc, val) => acc + val, 0);
              var percentage = ((value / total) * 100).toFixed(2);
              var result = Number(value).toFixed(2);
              label += "Current Value: " + '₹' + result + 'L' + " (" + percentage + "% of Total)";
              return label;
            }
          } 
        },
        
        title: {
          display: false,
          align: "start",
          text: "Current Valuation of Holdings - Startups",
          color: "Black",
          fontFamily: "Arial",
          fontSize: "18",
          padding: { bottom: 30 },
        },
        legend: {
          display: false,
          position: "right",
          align: "right",
        },
      },
      //customCanvasBackgroundColor: {
      //  color: 'white',
      //},
      responsive: true,
      maintainAspectRatio: false,
      //  plugins: [
      //    ChartPieChartOutlabels
      //  ]
    };

    const handleButtonClickforstartups = () => {
      setDisplayText('Current Value of Holdings - Startup Wise');
    };
    const handleButtonClickfordomains = () => {
      setDisplayText('Current Value of Holdings - Domain Wise');
    };
    const handleButtonClickforstage = () => {
      setDisplayText('Current Value of Holdings - Stage Wise');
    };   
    const handleButtonClickforival = () => {
      settreemapdisplayText('Amount Invested');
    };
    const handleButtonClickforcval = () => {
      settreemapdisplayText('Current Value of Holdings');
    };   
    const handleButtonClickforpnl = () => {
      settreemapdisplayText('Unrealized Profit and Loss');
    };   

// Creating the TreemapChart

// treemap data for startups/cval
const tmgroupedcval = v1inv.map((obj) => {
  return { startupname: obj.startupname, Cval_cr: Number(obj.Cval_cr).toFixed(2) };
}).sort((a, b) => b.Cval_cr - a.Cval_cr); 

const tmdataaa2 = {    
  datasets: [
    {
      tree: tmgroupedcval,
      key: 'Cval_cr',
      groups: ['startupname'],
      backgroundColor: (ctx)=> getColor(ctx),
      // labels: {
      //   display: false,
      //   align: left,
      //   font: {
      //     resizable: false,
      //     // minSize: 8,
      //     // maxSize: 16,
      //     // family: "Segoe UI",
      //     size: "12",
      //     fontWeight:'bold'
      //   },
      //   position:'top', 
              
      //   // formatter: (ctx) => ctx.raw.g,
      //   //formatter: (ctx) => `${ctx.raw.g}\n${ctx  .raw.v} L`,
      //   //formatter: (ctx) =>  ctx.raw.v + ' L'
      //   // children: [
      //   //   {
      //   //     display: true,
      //   //     align: 'left',
      //   //     position: 'bottom',
      //   //     formatter: (ctx) => ctx.raw.v + ' L'
      //   //   }
      //   // ]           
      // }
      //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]        
    },
  ],
};

// treemap data for startups/ival
const tmgroupedival = v1inv.map((obj) => {
  return { startupname: obj.startupname, ival_cr: Number(obj.ival_cr).toFixed(2) };
}).sort((a, b) => b.ival_cr - a.ival_cr); 

const tmdataaa3 = {    
  datasets: [
    {
      tree: tmgroupedival,
      key: 'ival_cr',
      groups: ['startupname'],
      backgroundColor: (ctx)=> getColor(ctx),
      // labels: {
      //   display: false,
      //   align: left,
      //   position:'top',         
      //   // formatter: (ctx) => ctx.raw.g,
      //   //formatter: (ctx) => `${ctx.raw.g}\n${ctx  .raw.v} L`,
      //   //formatter: (ctx) =>  ctx.raw.v + ' L'                  
      // }
      //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]        
    },
  ],
};

// treemap data for startups/cval
const tmgroupedpnl = v1inv.map((obj) => {
return { startupname: obj.startupname, pnl_cr: Number(obj.pnl_cr).toFixed(2) };
}).sort((a, b) => b.pnl_cr - a.pnl_cr); 

const tmdataaa4 = {    
datasets: [
  {
    tree: tmgroupedpnl,
    key: 'pnl_cr',
    groups: ['startupname'],
    backgroundColor: (ctx)=> getColor(ctx),
    // labels: {
    //   display: false,
    //   align: left,
    //   position:'top',         
    //   // formatter: (ctx) => ctx.raw.g,
    //   //formatter: (ctx) => `${ctx.raw.g}\n${ctx  .raw.v} L`,
    //   //formatter: (ctx) =>  ctx.raw.v + ' L'
    //   children: [
    //     {
    //       display: true,
    //       align: 'left',
    //       position: 'bottom',
    //       formatter: (ctx) => ctx.raw.v + ' L'
    //     }
    //   ]           
    // }
    //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]        
  },
],
};


const dataaa = {
  labels: v1inv.map((startup) => startup.startupname),
  datasets: [
    {
      data: v1inv.map((startup) => startup.Cval_cr),
      //backgroundColor: ["#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B","#3281B7","#2E927C","#EF9921","#7EA8C2","#73A59C","#F7CC8B"]
      //backgroundColor:  bgColors.map((color) => color.at),
      backgroundColor: ["#3281B7"]
      //(ctx)=> this.getColor(ctx)
    },
  ],
};

const [treemapData, settreemapData] = useState(tmdataaa2);

  function TreemapChart({ data }) {
    const treemapchartRef = useRef(null);
    const [chart, setChart] = useState(null);
  
    useEffect(() => {
      
      const myChartRef = treemapchartRef.current.getContext("2d");
        
      // Destroy the existing chart if it exists
      if (chart) {        
        chart.destroy();
       }
  
      // Create a new chart
      const newChart = new Chart(myChartRef, {
        type: "treemap",
        data: treemapData,        
        options: {                       
          animation:false,
          responsive:true,
          maintainAspectRatio: false,
          labels: {
            display: true,
            align: 'left',
            position: 'top',
            font: {
              family: 'Segoe UI',
              weight: 'bold',
              size: 12,              
            },
              formatter: (ctx) => [`${ctx.raw.g}`," ",`₹${ctx.raw.v.toFixed(2)} L`]
            // formatter: (ctx) => `${ctx.raw.g} \n newline`,                        
          } ,
          layout:{
            padding:{              
              bottom: '60'
            }             
            },                                                  
          plugins: {
              legend: {
                display: false
              },
              title: {
                display: true,            
              },
              tooltip: {
                enabled: true,
                backgroundColor: "white",
                bodyColor: "black",
                titleColor: "black",                
                callbacks: {
                  title: () => null,
                  label: function(ctx) {
                    var label = [`${ctx.raw.g}`,`₹${ctx.raw.v.toFixed(2)} L`]                    
                    return label;
                  }
                }                        
              }
              // datalabels: {
              //   display: 'true',
              //   anchor: 'start',
              //   clip: true,
              //   align: 45,
              //   color: 'black',
              //   overflow: 'hidden',   
              //   textAlign: 'left',            
              //   font: {
              //     size: 12,
              //     weight: 'bold'
              //   },  
              //   // font: {
              //   //   size: (context) => {
              //   //     const boxWidth = context.chart.width / context.chart.data.labels.length;
              //   //     const boxHeight = context.chart.height / context.chart.data.labels.length;
              //   //     const sizeThreshold = 50; // Adjust the size threshold as needed
            
              //   //     if (boxWidth < sizeThreshold || boxHeight < sizeThreshold) {
              //   //       return 12; // Hide the data label if the box is smaller than the size threshold
              //   //     } else {
              //   //       return 12; // Set the default font size when the box is large enough
              //   //     }
              //   //   },
              //   //   weight: 'bold'
              //   // },        
              //   formatter: (value) => `${value.g}` + `\n \n ₹${value.v.toFixed(2)}L`,
              //   // formatter: (value, context) => {
              //   //   const boxWidth = context.chart.widths[context.dataIndex];
              //   //   const boxHeight = context.chart.heights[context.dataIndex];
              //   //   const sizeThreshold = 50; // Adjust the size threshold as needed
        
              //   //   if (boxWidth < sizeThreshold || boxHeight < sizeThreshold) {
              //   //     return ''; // Hide the data label if the box is smaller than the size threshold
              //   //   } else {
              //   //     return `${value.raw.g}\n₹${value.raw.v.toFixed(2)} L`;
              //   //   }
              //   // },
              //   // listeners: {
              //   //   enter: function (context) {
              //   //     context.hovered = false; // Prevent treemap boxes from being highlighted on hover
              //   //     return false;
              //   //   }
              //   // },
              // },
              // datalabels: {
              //   display: true,
              //   // align: 'end',
              //   // anchor: 'center',
              //   // // backgroundColor: 'black',
              //   // borderRadius:'5',            
              //   // color:'black',
              //   // formatter: (ctx) => `${ctx.raw.g}\n₹${ctx.raw.v.toFixed(2)} L`,
              //   // // formatter:(value,context) => {
              //   // //   return context.chart.data.labels[context.dataIndex] + ': '+ `${value}%`;
              //   // // }
              //   },         
            },        
        },
        // plugins: [ChartDataLabels]
      });
  
      // Set the chart state to the new chart
      setChart(newChart);
  
      // Clean up function
      return () => {        
        if (chart) {
          
          chart.destroy();
        }
      };
    }, [data]);
    
    return (
      <div>
        <canvas ref={treemapchartRef} />
      </div>
    );
   }

  const updatetreemapData1 = () => {
    settreemapData(tmdataaa2);
  };
  
  const updatetreemapData2 = () => {
    settreemapData(tmdataaa3);
  };
  
  const updatetreemapData3 = () => {
    settreemapData(tmdataaa4);
  };


// data and options for investors and ivnestments - fund wise chart
  

const investorsdata = v1inv.reduce((acc, curr) => {
  const existingInvestor = acc.find(item => item.Investor === curr.Investor);
  if (existingInvestor) {
    const existingFundIndex = existingInvestor.fundname.findIndex(item => item === curr.fundname);
    if (existingFundIndex > -1) {
      existingInvestor.Cval_l[existingFundIndex] += parseFloat(curr.Cval_l);
    } else {
      existingInvestor.fundname.push(curr.fundname);
      existingInvestor.Cval_l.push(parseFloat(curr.Cval_l));
    }
  } else {
    acc.push({
      Investor: curr.Investor,
      fundname: [curr.fundname],
      Cval_l: [parseFloat(curr.Cval_l)]
    });
  }
  return acc;
}, []);


//_____ 

// // create an empty object to hold the final output
// const investors_and_investment_data = {
//   labels: [1,2],
//   datasets: [1,2]
// };

// Create an array of unique fund names
// const fundNames = investorsdata.reduce((acc, curr) => {
//   return [...acc, ...curr.fundname.filter((name) => !acc.includes(name))];
// }, []);



// // Create an object where each key is an Investor name, and the value is an array of Cval_l values for each fund they invested in
// const investorData = investorsdata.reduce((acc, curr) => {
//   const Investor = curr.Investor;
//   const cVals = curr.Cval_l;
//   const data = {};
//   curr.fundname.forEach((fund, index) => {
//     data[fund] = cVals[index];
//   });

//   if (acc[Investor]) {
//     acc[Investor].push(data);
//   } else {
//     acc[Investor] = [data];
//   }

//   return acc;
// }, {});




// // Create the data object for the chart
// const investors_and_investment_data2 = {
//   labels: Object.keys(investorData),
//   datasets: fundNames.map((name, index) => {
//     return {
//       label: name,
//       backgroundColor: 'black', // Generate a random hex color
//       data: Object.values(investorData).map((data) => {
//         return data[name] || 0; // If the Investor did not invest in this fund, set the value to 0
//       })
//     };
//   })
// };


// const chartLabels = investorsdata.map(data => data.Investor);


// // // Generate chart datasets
// const chartDatasets = [];

// const fundNames = [...new Set(investorsdata.flatMap(data => data.fundname))];

// for (const fundName of fundNames) {
//   const fundData = investorsdata.map(data => {
//     const index = data.fundname.indexOf(fundName);
//     return (index >= 0) ? data.Cval_l[index] : 0;
//   });
//   chartDatasets.push({
//     label: fundName,
//     data: fundData,
//     // backgroundColor: 'a0a7f9'
//     //  stack: 'investors',
//   });
// }




// // Generate chart data
// const investors_and_investment_data3= {
//   labels: chartLabels,
//   datasets: chartDatasets,  
// };



 


 

 var investors_and_investment_data = {
  labels: [],
  datasets: []
};

// // Create an array of unique fund names
// var fundNames = [];
// investorsdata.forEach(function(investorData) {
//   investorData.fundname.forEach(function(fundname) {
//     if (fundNames.indexOf(fundname) === -1) {
//       fundNames.push(fundname);
//     }
//   });
// });

// // Populate labels array
// investorsdata.forEach(function(investorData) {
//   investors_and_investment_data3.labels.push(investorData.Investor);
// });

// // Populate datasets array
// fundNames.forEach(function(fundname) {
//   var data = [];
//   investorsdata.forEach(function(investorData) {
//     var index = investorData.fundname.indexOf(fundname);
//     var value = (index !== -1) ? investorData.Cval_l[index] : 0;
//     data.push(value);
//   });
//   investors_and_investment_data3.datasets.push({
//     label: fundname,
//     data: data
//   });
// });

  //___________

  const domainwisehbOptions = {
    indexAxis: "y",
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Amount Invested + Unrealized P&L",
          // color: "black",
          font: {
            Family: "FF DIN",
            size: "12",
            // weight:'bold'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return "₹" + value + "L";
          },
        },
      },
      y: {
        stacked: true,
        barPercentage: 1,
        categoryPercentage: 1,
        title: {
          display: false,
          text: "Investors",
          // color: "black",
          font: {
            Family: "Segoe UI",
            size: "12",
            // weight:'bold'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    //circumference: 0,
    layout: {
      padding: {
        top: 20,
        bottom: 15,
      },
    },

    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        bodyColor: "Black",
        titleColor: "Black",
        callbacks: {
          label: function(context) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            var value = context.dataset.data[context.dataIndex];            
            var result = parseFloat(value).toFixed(2);
            label += '₹' + result + 'L';
            return label;
          }
        } 
      },
      legend: {
        display: 'auto',
        position: "top",
        align: "center",
      },
      datalabels: {
        display: 'auto',
        align: "center",
        anchor: "center",
        color: "contrast",        
        font: {
          family: "Segoe UI",
          size: 12,
          weight: "none"
        },
        borderRadius: 5,
        formatter: function(value, context) {
          if (value === 0 || value <= 0.03) {
            return '';
          } else {
            return '₹' + parseFloat(value).toFixed(2) + 'L';
          }
        }
      }
        
    },
    responsive: false,
    maintainAspectRatio: false,
  };

  const startupwisehbOptions = {
    indexAxis: "y",
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Amount Invested + Unrealized P&L",
          // color: "black",
          font: {
            Family: "FF DIN",
            size: "12",
            // weight:'bold'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return "₹" + value + "L";
          },
        },
      },
      y: {
        stacked: true,
        barPercentage: 1,
        categoryPercentage: 1,
        title: {
          display: false,
          text: "Investors",
          // color: "black",
          font: {
            Family: "Segoe UI",
            size: "12",
            // weight:'bold'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    //circumference: 0,
    layout: {
      padding: {
        top: 20,
        bottom: 15,
      },
    },

    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        bodyColor: "Black",
        titleColor: "Black",
        callbacks: {
          label: function(context) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            var value = context.dataset.data[context.dataIndex];            
            var result = parseFloat(value).toFixed(2); // subtract the second part from the first part and round the result to two decimal places
            label += '₹' + result + 'L';
            return label;
          }
        } 
      },
      legend: {
        display: true,
        position: "top",
        align: "center",
      },
      datalabels: {
        display: 'auto',
        align: "center",
        anchor: "center",
        color: "contrast",        
        font: {
          family: "Segoe UI",
          size: 12,
          weight: "none"
        },
        borderRadius: 5,
        formatter: function(value, context) {
          if (value === 0 || value <= 0.03) {
            return '';
          } else {
            return '₹' + parseFloat(value).toFixed(2) + 'L';
          }
        }
      }
        
    },
    responsive: false,
    maintainAspectRatio: false,
  };


  // *******************************************************************************************************************
      // console.log(tdata)

      const sortValues = (value1, value2)=>{
        if(value1<value2) return -1;
        else if(value2>value1) return 1;
        else return 0;
      }

     const handleObjectChange = (newObject)=>{
      let newSort = {};
          Object.keys({...sort}).forEach((key)=>{
            newSort = {...newSort, [key]:null}
          })
          newSort = {...newSort, ...newObject}
          setSort(newSort)
     }

      // domain sorting

      const sortDomain = ()=>{
        if(sort.domain){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(b.SU_Domain, a.SU_Domain);
          })
          settdata(sorted);
          handleObjectChange({domain:false})
        }else{
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(a.SU_Domain, b.SU_Domain)
          })
          settdata(sorted);
          handleObjectChange({domain:true})
        }
      }

    

      // startup sorting
      const sortStartupName = ()=>{
        if(sort.name){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(b.startupname, a.startupname);
          })
          settdata(sorted);
          handleObjectChange({name:false})
        }else{

          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(a.startupname, b.startupname)
          })
          settdata(sorted);
          // setStartupNameSort(true);
          handleObjectChange({name:true})

        }
      }

      // iprice:initial investment price value sort

      const sortIprice = ()=>{
        if(sort.iprice){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(Number(b.iprice), Number(a.iprice));
          })
          settdata(sorted);
          handleObjectChange({iprice:false})

        }else{

          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(Number(a.iprice), Number(b.iprice))
          })
          settdata(sorted);
          handleObjectChange({iprice:true})

        }
      }

      // Cpricepershare: current price per share value sort

      const sortCpricepershare = ()=>{
        if(sort.Cpricepershare){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(Number(b.Cpricepershare), Number(a.Cpricepershare));
          })
          settdata(sorted);
          handleObjectChange({Cpricepershare:false})

        }else{

          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(Number(a.Cpricepershare), Number(b.Cpricepershare))
          })
          settdata(sorted);
          handleObjectChange({Cpricepershare:true})

        }
      }

        // pre-money-val-startup: pre-money valuation value sort

        const sortPreMoneyValuation = ()=>{
          if(sort.pre_money_valuation_startup){
            const sorted = [...v1inv].sort((a,b)=>{
              return sortValues(Number(b.pre_money_valuation_startup), Number(a.pre_money_valuation_startup));
            })
            settdata(sorted);
            handleObjectChange({pre_money_valuation_startup:false})
  
          }else{
  
            const sorted = [...v1inv].sort((a,b)=>{
              return sortValues(Number(a.pre_money_valuation_startup), Number(b.pre_money_valuation_startup))
            })
            settdata(sorted);
            handleObjectChange({pre_money_valuation_startup:true})
  
          }
        }

      

      // investment value sort

      const sortInvestmentValue = ()=>{
        if(sort.ival){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(b.ival_cr, a.ival_cr);
          })
          settdata(sorted);
          handleObjectChange({ival:false})

        }else{

          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(a.ival_cr, b.ival_cr)
          })
          settdata(sorted);
          handleObjectChange({ival:true})

        }
      }

      // Current value sort

      const sortCurrentValue = ()=>{
        if(sort.cval){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(b.Cval_cr, a.Cval_cr);
          })
          settdata(sorted);
          handleObjectChange({cval:false})

        }else{

          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(a.Cval_cr, b.Cval_cr)
          })
          settdata(sorted);
          handleObjectChange({cval:true})

        }
      }

      // pnl sort

      const sortPnL = ()=>{
        if(sort.pnl){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(b.pnl_cr, a.pnl_cr);
          })
          settdata(sorted);
          // setPnLSort(false);
          handleObjectChange({pnl:false})

        }else{

          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(a.pnl_cr, b.pnl_cr)
          })
          settdata(sorted);
          // setPnLSort(true);
          handleObjectChange({pnl:true})

        }
      }

      // Startup valuation sort

      const sortStartupValuation = ()=>{
        if(sort.cvalStartup){
          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(b.Cval_startup_cr, a.Cval_startup_cr);
          })
          settdata(sorted);
          // setCvalStartupSort(false);
          handleObjectChange({cvalStartup:false})

        }else{

          const sorted = [...v1inv].sort((a,b)=>{
            return sortValues(a.Cval_startup_cr, b.Cval_startup_cr)
          })
          settdata(sorted);
          // setCvalStartupSort(true);
          handleObjectChange({cvalStartup:true})

        }
      }
    
    const onHover = (value)=>{
      setTableHeaderHover(prev=>{return {...prev, [value]:true}})
    }

    const onHoverLeave = (value) =>{
      setTableHeaderHover(prev=>{return {...prev, [value]:false}})
    }
    
// console.log(tableHeaderHover)
  // *******************************************************************************************************************


  return (    
    <div>
        <Helmet>
                <title>iAngel Investor Dashboard</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        </Helmet>
        <Grid container sx={{ backgroundColor: "#F7F7F7", border: { xs: "none", lg: "none" } }}>
      
      <Grid item xs={12} sm={6} lg={6} order={{ xs: 2, sm: 2, md: 2, lg: 1 }} sx={{border:'none'}}
      container
      display={'flex'}
      justifyContent={'flex-start'}
      pt={{lg:2}}
      pb={{lg:1}}
      >          
        
      <Button startIcon={<MenuIcon />} size="large" onClick={handleClickOpen}></Button>      
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} >
      <DialogTitle sx={{backgroundColor: "#F7F7F7" }}>Filter Selection Menu</DialogTitle>
      <DialogContent sx={{ width: 320, height: 180, backgroundColor: "#F7F7F7"  }}>
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', backgroundColor: "#F7F7F7" }}>
        <Grid
        container
        display={"flex"}
        // direction={"row-reverse"}
        sx={{ backgroundColor: "#F7F7F7" }}
        // sx={{ border: "solid"}}
       >        

      <Grid item>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
          <InputLabel id="demo-multiple-checkbox-label">Investment Type</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={cinvtypeName}
            onChange={handleChangecinvtype}
            input={<OutlinedInput label="Funds" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}>
            {checks_invtype.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={cinvtypeName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 300 }} size="small">
          <InputLabel id="demo-multiple-checkbox-label">Startup</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={cstartupName}
            onChange={handleChangecstartup}
            input={<OutlinedInput label="Funds" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}>
            {checks_startup.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={cstartupName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 300 }} size="small">
          <InputLabel id="demo-multiple-checkbox-label">Domain</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={cdomainName}
            onChange={handleChangecdomain}
            input={<OutlinedInput label="Funds" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}>
            {checks_domain.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={cdomainName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{backgroundColor: "#F7F7F7"}} >          
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>      
        <Typography 
        pl={{ xs: 5, lg: 0 }} 
        pr={{ xs: 2, lg: 0 }} 
        pt={{ xs: 0, lg: 1 }} 
        pb={{ xs: 0, lg: 1 }} 
        m={0} 
        fontFamily="Segoe UI" variant="h6">
          Hi,   
          {/* {iangname} */}
        </Typography>    
        <FormControl variant='standard' sx={{ ml: 1, minWidth: 200 }} size="small">
          <InputLabel id="demo-simple-select-standard-label">Investor</InputLabel>
          <Select
            // defaultValue = ""
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            multiple            
            value={cinvestorName}
            onChange={handleChangecinvestor}
            // input={<OutlinedInput label="Funds" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}>
            {checks_investor.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={cinvestorName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
       
      </Grid>
      
    
      <Grid item  xs={12} lg={6} sx={{ border: "none" }} order={{ xs: 1, sm: 1, md: 1, lg: 2 }} 
      pr={{ xs: 0, lg: 7 }}
      pt={{lg:2}}
      pb={{lg:1}}>
        
        <Grid 
        display={"flex"} 
        direction={{ xs: "row", lg: "row-reverse" }} justifyContent={{ xs: "flex-start", lg: "end" }} pr={1} mt={0} container sx={{ backgroundColor: "#F7F7F7" }} border={"none"}>          
          {/* <Grid item pl={{ xs: 2, lg: 2 }} pt={{ xs: 0, lg: 1 }} sx={{ border: "none" }} xs={6} lg={2} pr={3} mr={20}>
        
            <img
              src={logo}
              alt="IA Logo"
              style={{
                maxWidth: "300px",
                maxHeight: "35px",
                paddingRight: "10px",
              }}
            />
          </Grid> */}
          
          <Grid item pt={{lg:3}} pl={{ xs: 0, lg: 0 }} xs={6} lg={5} sx={{ border: "none" }} align={{ lg: "right" }}>
            {/* <Typography fontFamily="Segoe UI" fontSize={{ xs: 12, lg: 16 }} align="right"> */}
              {/* IA-Mini ID :- {iamid}, ({current_date}) */}   
            {/* </Typography> */}
            {/* <Typography fontFamily="Segoe UI" fontSize={{ xs: 12, lg: 16 }} fontWeight={"bold"} align="right">
              <Link href='/login' onClick={handleLinkClickiang} underline="always">Sign Out</Link>
            </Typography>               */}
          </Grid>            
        </Grid>
      </Grid>
    </Grid>

    <Grid container>
        <Grid item lg={12}>
          <Divider sx={{ color: "#DBDBDB" }}></Divider>
        </Grid>
      </Grid> 
      <Grid
          container
          display={'flex'}
          sx={{ backgroundColor: '#F7F7F7' }}
        //sx={{ border: "solid"}}
        >
          <Grid item pl={5} pt={1}>
            <WorkOutlineIcon pt={5} fontSize="small"></WorkOutlineIcon>
          </Grid>
          <Grid item>
            <Typography m={1} fontFamily="Segoe UI" fontSize={16}> Holdings ({startupscovered} Startups) </Typography>
          </Grid>
        </Grid>




      {/* <Grid container sx={{ backgroundColor: '#F7F7F7' }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography pl={5} pt={2} pb={1} m={1} fontFamily="Segoe UI" variant={'h6'}> Overview of Holdings for {iamname} </Typography>
          <Divider sx={{ color: "#DBDBDB" }}></Divider>
        </Grid>

        <Grid
          container
          display={'flex'}
          sx={{ backgroundColor: '#F7F7F7' }}
        //sx={{ border: "solid"}}
        >
          <Grid item pl={5} pt={1}>
            <WorkOutlineIcon pt={5} fontSize="small"></WorkOutlineIcon>
          </Grid>
          <Grid item>
            <Typography m={1} fontFamily="Segoe UI" fontSize={16}> Holdings ({startupscovered} Startups) </Typography>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid container
        sx={{ backgroundColor: '#F7F7F7' }}
        display={'flex'}
        // justifyContent={'space-evenly'}
        alignItems={'start'}
        pl={5}
        pt={1}
        pb={0}
        mb={-3}
      // sx={{ border: "solid"}}
      >
        <Grid item xs={6} lg={2}>
          <Typography fontFamily="Segoe UI" fontSize={16} color='#80898A'> Total Investment </Typography>
          <Typography fontFamily="Segoe UI" fontSize={34} color='#000000'> {formatter.format(total_inv)}L </Typography>
        </Grid>
        <Grid item xs={6} lg={2}>
          <Typography fontFamily="Segoe UI" fontSize={16} color='#80898A'> Current Value </Typography>
          <Typography fontFamily="Segoe UI" fontSize={34} color='#000000'> {formatter.format(current_value)}L </Typography>
        </Grid>

        <Grid item xs={6} lg={2}>
          <Typography fontFamily="Segoe UI" fontSize={16} color='#80898A'> Unrealized Total P&L </Typography>
          <Typography fontFamily="Segoe UI" fontSize={34} color='#000000' sx={{ color: pnl >= 0 ? 'green' : 'red' }}> {formatter.format(pnl)}L </Typography>
        </Grid>
        <Grid item xs={6} lg={2}>
          <Typography fontFamily="Segoe UI" fontSize={16} color='#80898A'> Return on Investment </Typography>
          <Typography fontFamily="Segoe UI" fontSize={34} color='#000000' sx={{ color: (current_value / total_inv) >= 1 ? 'green' : 'red' }}> {((current_value / total_inv).toFixed(1))}x </Typography>
        </Grid>        
      </Grid>  
           
      <Grid container
        sx={{ backgroundColor: '#F7F7F7' }}
        display={'flex'}
        justifyContent='flex-end'
        pt={0}
        mb={-2}
        //sx={{ border: "solid" }}
      >
        <Grid item pl={1} pt={2} m={1} >
        <Typography fontFamily="Segoe UI" fontSize={16}> View by :- </Typography>
        
        </Grid>        
        <Grid item pt={2} m={1}>
          <Button onClick={() => { updatetreemapData1(); handleButtonClickforcval(); }} variant='outlined' sx={{ textTransform: 'none', width: '150px', height: '28px',":hover": { bgcolor: "#2E927C", color: "white" }}}>Current Value</Button>
        </Grid>
        <Grid item pt={2} m={1}>
          <Button onClick={() => { updatetreemapData2(); handleButtonClickforival(); }} variant='outlined' sx={{ textTransform: 'none', width: '150px', height: '28px',":hover": { bgcolor: "#2E927C", color: "white"}}}>Amount Invested</Button>
        </Grid>
        <Grid item pr={1} pt={2} m={1}>
          <Button onClick={() => { updatetreemapData3(); handleButtonClickforpnl(); }} variant='outlined' sx={{ textTransform: 'none', width: '150px', height: '28px',":hover": { bgcolor: "#2E927C", color: "white" }}}>P&L</Button>
        </Grid>
      </Grid>
      <Grid container
        sx={{ backgroundColor: '#F7F7F7' }}
        display={'flex'}
        justifyContent='space-evenly'
        pt={1}
        //sx={{ border: "solid" }}
      >
        <Grid item p={1} m={1} lg={12}>
          <Paper elevation={3} sx={{ paddingLeft:'10px', paddingRight:'10px', borderRadius: '10px', border: 'none', height: '100px'}} >



          <Typography pl={1} pt={1} m={-1} fontFamily={"Arial"} fontSize={14} fontWeight={'bold'} color={"Black"} height={'1%'}> {treemapdisplayText} </Typography>                            
          <TreemapChart 
          // data={dataaa} 
          data={treemapData} 
          />





          </Paper></Grid>
      </Grid>
      <Divider sx={{ color: "#DBDBDB" }}></Divider>
      <Grid
        container
        display={"flex"}        
        // wrap="nowrap"
        sx={{ backgroundColor: '#F7F7F7' }}
        // justifyContent='start'
        //sx={{ border: "solid" }}
      >
        <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 2 }} xs={12} lg={5}>
          <Paper elevation={3} sx={{ padding: '10px', maxWidth: "100%", height: '480px', borderRadius: '10px', border: 'none' }} >
              <Typography fontFamily={"Arial"} fontSize={18} fontWeight={'bold'} color={"Black"} height={'6%'}> {displayText} </Typography>                            
              
              <canvas ref={piechartRef} />              
        <Grid container        
        display={'flex'}
        direction={'row'}
        justifyContent='flex-start'
        alignItems = 'flex-start'
        pt={0}
        mt={-3}
        //sx={{ border: "solid" }}      
        >
        
      <Grid item pl={{xs:0, lg:0}} pt={{xs:1, lg:2}} xs={2}>
        <Typography fontFamily="Segoe UI" fontSize={16} align='right'> View by:- </Typography>        
        </Grid>
        <Grid item pl={{xs:3, lg:1}} pt={{xs:1, lg:2}} xs={2}>
        <Button onClick={() => { updateChartData1(); handleButtonClickforstartups(); }} sx={{ backgroundColor: '#178ACC', color: 'white', textTransform: 'none', width: '150px', height: '28px', ":hover": { bgcolor: "#2E927C", color: "white" } }}>Startups</Button>
        </Grid>
        <Grid item pl={{xs:15, lg:9}} pt={{xs:1, lg:2}} pr={{xs:1, lg:2}} xs={2}>
          <Button onClick={() => { updateChartData2(); handleButtonClickfordomains(); }} sx={{backgroundColor:'#178ACC', color: 'white', textTransform: 'none', width: '150px',height: '28px',":hover": { bgcolor: "#2E927C", color: "white"}}}>Domain</Button>
        </Grid>        
        <Grid item pl={{xs:15, lg:17}} pt={{xs:1, lg:2}} pr={{xs:1, lg:2}} xs={2}>
          <Button onClick={() => { updateChartData3(); handleButtonClickforstage(); }} sx={{backgroundColor:'#178ACC', color: 'white', textTransform: 'none', width: '150px',height: '28px',":hover": { bgcolor: "#2E927C", color: "white"}}}>Stage</Button>
        </Grid>        
        </Grid>

              
          </Paper></Grid>
          <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 3, lg: 1 }} pl={{ xs: 3, lg: 2 }} pr={{ xs: 3, lg: 2 }} xs={12} lg={7}>
          <Paper sx={{ padding: '10px', maxWidth: "100%", height: '480px', borderRadius: '10px' }} elevation={3} >
            <Box sx={{display:"flex", justifyContent:"space-between", marginRight:"10px"}}>
             <Typography fontFamily={"Arial"} fontSize={18} fontWeight={'bold'} color={"Black"}> Portfolio Analysis </Typography>
             <ExportDataToExcel investmentData={originalData} fileName="Investment Data"/>
            </Box>
            <TableContainer m={1} p={1} sx={{ maxHeight: "95%", maxWidth: "100%" }}>
              <Table aria-label="a dense table" size='small' stickyHeader>
                <TableHead >
                  <TableRow>
                    <TableCell onMouseEnter={()=>onHover("domain")} onMouseLeave={()=>onHoverLeave("domain")} onClick={sortDomain} sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"}}} align='left'> <div style={{display:"flex", alignItems:"center"}}> Domain {sort.domain===false&&tableHeaderHover.domain||sort.domain===false?<SouthIcon  sx={{fontSize:"14px"}}/>:sort.domain||tableHeaderHover.domain?<NorthIcon sx={{fontSize:"14px"}}/>:""} </div></TableCell>
                    <TableCell onMouseEnter={()=>onHover("name")} onMouseLeave={()=>onHoverLeave("name")} onClick={sortStartupName} sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="left"> <div style={{display:"flex", alignItems:"center"}}> Startup Name {sort.name===false&&tableHeaderHover.name||sort.name===false?<SouthIcon  sx={{fontSize:"14px"}}/>:sort.name||tableHeaderHover.name?<NorthIcon sx={{fontSize:"14px"}}/>:""} </div></TableCell>
                    <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6' }} align="left"> Inv. Type </TableCell>
                    <TableCell onMouseEnter={()=>onHover("iprice")} onMouseLeave={()=>onHoverLeave("iprice")} onClick={sortIprice} sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"> <div style={{display:"flex", alignItems:"center"}}>Price/Share Pre-Money Val {sort.iprice===false&&tableHeaderHover.iprice||sort.iprice===false?<SouthIcon  sx={{fontSize:"14px"}}/>:sort.iprice||tableHeaderHover.iprice?<NorthIcon sx={{fontSize:"14px"}}/>:""} </div></TableCell>
                    <TableCell onMouseEnter={()=>onHover("ival")} onMouseLeave={()=>onHoverLeave("ival")} onClick={sortInvestmentValue} sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"> <div style={{display:"flex", alignItems:"center"}}>Inv. Value {sort.ival===false&&tableHeaderHover.ival||sort.ival===false?<SouthIcon  sx={{fontSize:"14px"}}/>:sort.ival||tableHeaderHover.ival?<NorthIcon sx={{fontSize:"14px"}}/>:""} </div></TableCell>
                    <TableCell onMouseEnter={()=>onHover("Cpricepershare")} onMouseLeave={()=>onHoverLeave("Cpricepershare")} onClick={sortCpricepershare} sx={{ fontFamily: "Segoe UI", fontSize: "18", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"><div style={{display:"flex", alignItems:"center"}}>Cur. Price/Share {sort.Cpricepershare===false&&tableHeaderHover.Cpricepershare||sort.Cpricepershare===false?<SouthIcon  sx={{fontSize:"14px" }}/>:sort.Cpricepershare||tableHeaderHover.Cpricepershare?<NorthIcon sx={{fontSize:"14px"}}/>:""}</div></TableCell>
                    <TableCell onMouseEnter={()=>onHover("cval")} onMouseLeave={()=>onHoverLeave("cval")} onClick={sortCurrentValue} sx={{ fontFamily: "Segoe UI", fontSize: "18", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"><div style={{display:"flex", alignItems:"center"}}>Cur. Value {sort.cval===false&&tableHeaderHover.cval||sort.cval===false?<SouthIcon  sx={{fontSize:"14px" }}/>:sort.cval||tableHeaderHover.cval?<NorthIcon sx={{fontSize:"14px"}}/>:""}</div></TableCell>
                    <TableCell onMouseEnter={()=>onHover("pnl")} onMouseLeave={()=>onHoverLeave("pnl")} onClick={sortPnL} sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"> <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}> P&L {sort.pnl===false&&tableHeaderHover.pnl||sort.pnl===false?<SouthIcon  sx={{fontSize:"14px"}}/>:sort.pnl||tableHeaderHover.pnl?<NorthIcon sx={{fontSize:"14px"}}/>:""} </div></TableCell>
                    <TableCell onMouseEnter={()=>onHover("pre_money_valuation_startup")} onMouseLeave={()=>onHoverLeave("pre_money_valuation_startup")} onClick={sortPreMoneyValuation} sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"> <div style={{display:"flex", alignItems:"center"}}>Pre-Money Valuation of Startup {sort.pre_money_valuation_startup===false&&tableHeaderHover.pre_money_valuation_startup||sort.pre_money_valuation_startup===false?<SouthIcon  sx={{fontSize:"14px"}}/>:sort.pre_money_valuation_startup||tableHeaderHover.pre_money_valuation_startup?<NorthIcon sx={{fontSize:"14px"}}/>:""} </div></TableCell>
                    <TableCell onMouseEnter={()=>onHover("cvalStartup")} onMouseLeave={()=>onHoverLeave("cvalStartup")} onClick={sortStartupValuation} sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"> <div style={{display:"flex", alignItems:"center"}}>Latest Valuation of Startup {sort.cvalStartup===false&&tableHeaderHover.cvalStartup||sort.cvalStartup===false?<SouthIcon  sx={{fontSize:"14px"}}/>:sort.cvalStartup||tableHeaderHover.cvalStartup?<NorthIcon sx={{fontSize:"14px"}}/>:""} </div></TableCell>
                    <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "40px", color: '#A6A6A6', ":hover":{cursor:"pointer"} }} align="center"> <div style={{display:"flex", alignItems:"center"}}> X Returns </div></TableCell>
                    <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "18", width: "90px", color: '#A6A6A6' }} align="center">Current Stage</TableCell>                                        
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tdata.map((tdata, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} component="th" scope="row">{tdata.SU_Domain}</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="left">{tdata.startupname}</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="left">{tdata.share_type}</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">₹{Number(tdata.iprice).toFixed(2)}</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">₹{Number(tdata.ival_cr).toFixed(2)}L</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">₹{Number(tdata.Cpricepershare).toFixed(2)}</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">₹{Number(tdata.Cval_cr).toFixed(2)}L</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">₹{Number(tdata.pnl_cr).toFixed(2)}L</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">₹{Number(tdata.pre_money_valuation_startup).toFixed(2)}L</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">₹{Number(tdata.Cval_startup_cr).toFixed(2)}L</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="right">{(Number(tdata.Cpricepershare)/Number(tdata.iprice)).toFixed(2)}</TableCell>
                      <TableCell sx={{ fontFamily: "Segoe UI", fontSize: "20", overflow: 'hidden', whiteSpace: 'nowrap' }} align="center">{tdata.stage}</TableCell>                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      
      <Grid container pt={6}>
        <Grid item lg={12}>
          <Divider sx={{ color: "#DBDBDB" }}></Divider>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ backgroundColor: "#F7F7F7" }}
        display={"flex"}
        justifyContent="start"
        pt={1}
        mb={0}
        //sx={{ border: "solid" }}     
      >
        <Grid item p={{ xs: 3, lg: 0 }} pr={{ lg: 0 }} pt={{ xs: 2, lg: 1 }} pl={{ xs: 3, lg: 2 }} xs={12} lg={6}
        //  sx={{ overflowY: "scroll", maxHeight: "100%" }}
        >
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none"                            
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"} pb={1}>
              Return on Investment - Startup Wise              
            </Typography>   
            <Grid container display="flex" sx={{ border: 'none', overflowY: 'auto', height:'290px'}} 
            // ref={chartContainerRef}
            >
            {startupwisehbData && (
              <Bar
                key={chartHeight}
                p={1}
                height={chartHeight}
                width="800"
                data={startupwisehbData}
                options={startupwisehbOptions}
                plugins={[ChartDataLabels]}
                />
              )}
            </Grid>                            
          </Paper>          
        </Grid>

        <Grid item p={{ xs: 3, lg: 0 }} pt={{ xs: 0, lg: 1 }} pl={{ xs: 3, lg: 2 }} pr={{ xs: 3, lg: 2 }} xs={12} lg={6}>
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              maxWidth: "100%",
              height: "320px",
              borderRadius: "10px",
              border: "none",
            }}>
            <Typography fontFamily={"Arial"} fontSize={18} fontWeight={"bold"} color={"Black"} height={"6%"} pb={1}>
            Return on Investment - Domain Wise
            </Typography>
            <Grid container display="flex" sx={{ border: 'none', overflowY: 'auto', height:'290px'}} 
            // ref={chartContainerRef}
            >
            {domainwisehbData && (
              <Bar
                key={chartHeight2}
                p={1}
                height={chartHeight2}
                width="800"
                data={domainwisehbData}
                options={domainwisehbOptions}
                plugins={[ChartDataLabels]}
                />
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container sx={{ backgroundColor: '#F7F7F7' }} display={'flex'} justifyContent='flex-start' pt={0} mt={-3} 
      //sx={{ border: "solid" }}
      >
        <Grid item lg= {6} >
        
       
        
        <Grid container        
        display={'flex'}
        justifyContent='flex-start'
        pt={0}
        mt={0}
        //sx={{ border: "solid" }}
      >
        {/* <Grid item pl={1} pt={1} m={1} >
        <Typography fontFamily="Segoe UI" fontSize={16}> See above chart by :- </Typography>        
        </Grid>
        <Grid item pt={1} m={1}>
        <Button onClick={() => { updateChartData1(); handleButtonClickforstartups(); }} sx={{ backgroundColor: '#178ACC', color: 'white', textTransform: 'none', width: '150px', height: '28px', ":hover": { bgcolor: "#2E927C", color: "white" } }}>Startups</Button>
        </Grid>
        <Grid item pt={1} m={1}>
          <Button onClick={() => { updateChartData2(); handleButtonClickfordomains(); }} sx={{backgroundColor:'#178ACC', color: 'white', textTransform: 'none', width: '150px',height: '28px',":hover": { bgcolor: "#2E927C", color: "white"}}}>Domain</Button>
                  </Grid> */}        

        </Grid>        
        </Grid>
        <Grid item  lg= {6} pt={{lg:0}}>
        <Grid
            container
            display={"flex"}
            direction={"row-reverse"}
            justifyContent="flex-start"
            pr={1}
            mt={0}
            //sx={{ border: "solid" }}
          >
            {/* <Grid item pl={{ xs: 0, lg: 1 }} pt={{ xs: 3, lg: 3 }} pr={{ xs: 0, lg: 2 }}>
              <img src={logo} alt="IA Logo" style={{ width: 300, height: 35 }} />
            </Grid> */}
            <Grid item pl={{ xs: 3, lg: 1 }} pt={{ xs: 0, lg: 5 }} pr={{ lg: 2 }}>
              <Typography fontFamily="Segoe UI" fontSize={16}>
                {" "}
                Report Last Updated :- {rlatest_date}{" "}
              </Typography>
            </Grid>
          </Grid></Grid>
      </Grid>
    </div>    
  );
}

export default SyndicateAccess;