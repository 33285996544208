import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ialogo from "../icons/ialogo.png";
import axios from "axios";
import { useNavigate } from "react-router";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.indiaaccelerator.co/">
        India Accelerator
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      All Rights Reserved.
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function LoginPage() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [errors, setErrors] = React.useState("")
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const userCredentials = {email: formData.get("email").trim(), password: formData.get("password").trim()};

    try{
      const response = await axios.post(`${API_BASE_URL}/api/login`, userCredentials);
      if(response.status===200){
        localStorage.setItem("investorData",JSON.stringify(response.data.data));
        navigate("/investments");
      }
    }catch(error){
      setErrors("Invalid credentials!")
    }
  };

  const investorData = JSON.parse(localStorage.getItem("investorData"))

    React.useEffect(()=>{
    const investorData = JSON.parse(localStorage.getItem("investorData"))
        if(investorData?.jwt){
            navigate("/investments")
            return
        }
    },[investorData?.jwt])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={ialogo}
            alt="IA Logo"
            style={{
              maxWidth: "270px",
              maxHeight: "180px",
              paddingRight: "0px",
              paddingBottom: "10px",
            }}
          />

          <Typography
            component="h2"
            variant="h5"
            marginTop={"12px"}
            marginBottom={"12px"}
          >
            iAngel Login
          </Typography>

          {errors ? <Typography
            component="p"
            color={"red"}
            marginTop={"12px"}
            marginBottom={"12px"}
          >
            {errors}
          </Typography>:""}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container textAlign={"center"}>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
