import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import Iangel from "../pages/iangel";
import NewInvestorPage from "../pages/NewInvestorPage";
import Home from "../pages/Home";
import SyndicateAccess from "../pages/SyndicateAccess";
import SyndicatePortfolio from "../pages/SyndicatePortfolio";

const AllRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/investments" element={<NewInvestorPage />} />
        <Route path="/iangels-syndicate-access/:investorEmail" element={<SyndicateAccess />} />
        <Route path="/iangels-syndicate-access/portfolio/:email" element={<SyndicatePortfolio />} />
      </Routes>
    </div>
  );
};

export default AllRoutes;
